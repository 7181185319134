import React, { useState, useEffect } from "react";
import { number } from "yup";
import { generateImage } from "../../tools/constants";
// import { Form, Field, Formik, ErrorMessage } from "formik";
// import * as Yup from "yup";
import gtag from 'ga-gtag';
import ScrollToTop from "../../../src/ScrollToTop";
const RecipientName = (props) => {
// console.log("data", props.data.recipientName)
  // console.log(props);
  const [message, setMessage] = useState("");
  const [recipient, setRecipient] = useState(props.data.recipientName);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validation(recipient)){ props.next("recipientName", recipient);
  
    gtag('event', "stepTwoGift", {
      event_category: "signupFlow",
      event_label:recipient,
    });
  }
  };

  const firstNameChange = (e) => {
    validation(e.target.value);
    setRecipient(e.target.value);
  };

  const validation = (val) => {
    var regex = /^[a-zA-Z- ]{1,30}$/;

    if (val.length === 0) {
      setMessage("");
      return false;
    } else if (!regex.test(val)) {
      setMessage("Enter a valid name!");
      return false;
    } else {
      setMessage("");
      return true;
    }
  };
 
  return (
    <ScrollToTop>
    <div className=" container">
     
      <h3 className="step-header"> Thanks, <span className="step-name">{props.data.firstName}</span>! Please tell us who you’d like to send a gift to.
</h3> 

      <form className="step-form" onSubmit={handleSubmit}>
        <input
          className="step-one"
          type="text"
          placeholder="Enter First Name"
          id="RecipientName"
          name="RecipientName"
          value={recipient}
          onChange={firstNameChange}
        ></input>
        {message ? <p className="error-msg stepone-error">{message}</p> : null}
        {/* <p className="error-msg">{message}</p> */}
        <div className="mtb-20">
          <input className="step-button" type="submit" value="Next"></input>
        </div>
      </form>

      <div className="go-back"  onClick={props.prev} ><p>Go Back</p></div>
    </div>
    </ScrollToTop>
  );
};

export default RecipientName;
