import React, { useState, useEffect } from "react";

import { GoCheck } from "react-icons/go";
import axios from "axios";
import Loader from "../../Loader";
import RecipientName from "../StepsGift/stepTwo";
import StepOneGift from "../StepsGift/stepOne";
import StepThreeGift from "../StepsGift/stepThree";
import StepFourGift from "../StepsGift/stepFour";
import StepFiveGift from "../StepsGift/stepFive";
import StepSixGift from "../StepsGift/stepSix";
import StepSevenGift from "../StepsGift/stepSeven";
import StepEightGift from "../StepsGift/stepEight";
import CustomFormSignup from "../../tools/Signupemail";

import Followers from "./Followers";
import { Link } from "react-router-dom";
const StepsGift = () => {
  const [steps, setSteps] = useState({
    steps: [],
    isLoaded: false,
  });
  const [page, setPage] = useState({
    pages: [],
    isLoaded: false,
  });

  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/sign-up?populate=deep,10")
      .then((resStep) => {
        setSteps({
          steps: resStep.data.data.attributes,
          isLoaded: true,
        });
      });
    axios
      .get("https://cms.cleanfitbox.com/api/homepage?populate=deep,10")
      .then((res) => {
        setPage({
          pages: res.data.data.attributes,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
      document.title="Subscription box for healthy snacks that are ACTUALLY healthy."
      document.getElementsByTagName("META")[2].content="Delicious clean snacks, superfoods, and supplements delivered monthly. Organic and all-natural. Vegan, Vegetarian, Gluten-free and Dairy-free options.      "
  }, []);

  // console.log(steps.pages);
  const [step, setStep] = useState(0);

  const [data, setData] = useState({
    firstName: "",
    pickedBox: "",
    email: "",
    recipientName: "",
    pickPreference: "",
    recommendation: "",
    pickBoxShop: "",
    pickPreferenceTitle: [],
    gift: true,
  });

  // const [errors, setErrors] = useState({});

  // const makeRequest = (formData) => {
  //   console.log("form submitted", formData);
  // };

  const handleNextStep = (name, value, final = false) => {
    setData((prev) => ({ ...prev, [name]: value }));

    // if (final) {
    //   // makeRequest(newData);
    //   return;
    // }
    setStep((prev) => prev + 1);
  };

  const handlePickStep = (name, value, final = false) => {
    setData((prev) => ({ ...prev, [name]: value }));

    // if (final) {
    //   // makeRequest(newData);
    //   return;
    // }
    setStep((prev) => prev);
  };

  const handlePrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setStep((prev) => prev - 1);
  };

  useEffect(() => {
    // console.log(data);
  }, [data]);
  // console.log(data);
  const checkNextStepAvailability = (stepNumber) => {
    switch (stepNumber) {
      case 1:
        if (data.firstName.length > 1) return true;
        return false;
      case 2:
        if (data.firstName.length > 1 && data.recipientName.length > 0)
          return true;
        return false;
      case 3:
        if (
          data.firstName.length > 1 &&
          data.recipientName.length > 0 &&
          data.pickedBox.length > 0
        )
          return true;
        return false;
      case 4:
        if (
          data.firstName.length > 1 &&
          data.recipientName.length > 0 &&
          data.pickedBox.length > 0 &&
          data.email.length > 0
        )
          return true;
        return false;
      case 5:
        if (
          data.firstName.length > 1 &&
          data.recipientName.length > 0 &&
          data.pickedBox.length > 0 &&
          data.email.length > 0 &&
          data.pickPreference.length > 0
        )
          return true;
        return false;
      case 6:
        if (
          data.firstName.length > 1 &&
          data.recipientName.length > 0 &&
          data.pickedBox.length > 0 &&
          data.email.length > 0 &&
          data.pickPreference.length > 0 &&
          data.recommendation.length > 0
        )
          return true;
        return false;
      case 7:
        if (
          data.firstName.length > 1 &&
          data.recipientName.length > 0 &&
          data.pickedBox.length > 0 &&
          data.email.length > 0 &&
          data.pickPreference.length > 0 &&
          data.recommendation.length > 0 &&
          data.pickBoxShop.length > 0
        )
          return true;
        return false;

      default:
        return false;
    }
  };

  const stepsOption = [
    <StepOneGift
      next={handleNextStep}
      data={data}
      page={steps.steps}
      icon={page.pages}
    />,
    <RecipientName
      next={handleNextStep}
      data={data}
      page={steps.steps}
      prev={handlePrevStep}
    />,
    <StepThreeGift
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      page={steps.steps}
    />,
    // <StepFourGift
    //   next={handleNextStep}
    //   prev={handlePrevStep}
    //   data={data}
    //   page={steps.steps}
    // />,
    <CustomFormSignup
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      page={steps.steps}
    />,
    <StepFiveGift
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      page={steps.steps}
      handlePickStep={handlePickStep}
    />,
    <StepSixGift
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      page={steps.steps}
    />,
    <StepSevenGift
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      page={steps.steps}
    />,
    // <StepEightGift
    //   next={handleNextStep}
    //   prev={handlePrevStep}
    //   data={data}
    //   page={steps.steps}
    // />,
  ];

  if (steps.isLoaded === false || page.isLoaded === false) return <Loader />;
  else {
    // console.log("dataaaa", data.email.length);
    return (
      <div className="steps">
                <div className="offer" ><p>FREE Bonus Box Worth up to $150 with Gift Subs 🎁</p></div>

        <div className="steps-display">
          <Link   to={
                    window.location.pathname.includes("homepage")
                      ? "/homepage"
                      : "/"
                  }>
            <div className="nav-logo">
              {" "}
              <img src="../images/logo.svg"></img>
            </div>
          </Link>
        </div>

        <ul>
          <li
            onClick={() => setStep(0)}
            className={`${step === 0 ? "active_step" : "newclass"}`}
          >
            {step > 0 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepOne} </p>
            )}
          </li>
          <div className="progress-line"></div>
          <li
            onClick={() => (checkNextStepAvailability(1) ? setStep(1) : null)}
            className={`${
              step === 1 ? "active_step" : step > 1 ? "newclass" : ""
            }`}
          >
            {step > 1 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepTwo} </p>
            )}
          </li>
          <div className="progress-line"></div>
          <li
            onClick={() => (checkNextStepAvailability(2) ? setStep(2) : null)}
            className={`${
              step === 2 ? "active_step" : step > 2 ? "newclass" : ""
            }`}
          >
            {step > 2 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepThree} </p>
            )}
          </li>
          <div className="progress-line"></div>
          <li
            onClick={() => (checkNextStepAvailability(3) ? setStep(3) : null)}
            className={`${
              step === 3 ? "active_step" : step > 3 ? "newclass" : ""
            }`}
          >
            {step > 3 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepFour} </p>
            )}
          </li>
          <div className="progress-line"></div>
          <li
            onClick={() => (checkNextStepAvailability(4) ? setStep(4) : null)}
            className={`${
              step === 4 ? "active_step" : step > 4 ? "newclass" : ""
            }`}
          >
            {step > 4 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepFive} </p>
            )}
          </li>
          <div className="progress-line"></div>
          <li
            onClick={() => (checkNextStepAvailability(5) ? setStep(5) : null)}
            className={`${
              step === 5 ? "active_step" : step > 5 ? "newclass" : ""
            }`}
          >
            {step > 5 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepSix} </p>
            )}
          </li>
          <div className="progress-line"></div>
          <li
            onClick={() => (checkNextStepAvailability(6) ? setStep(6) : null)}
            className={`${
              step === 6 ? "active_step" : step > 6 ? "newclass" : ""
            }`}
          >
            {step > 6 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepSeven} </p>
            )}
          </li>
        </ul>

        <div>{stepsOption[step]}</div>
        <div className="pt-50">
          <Followers page={page.pages} />
        </div>
      </div>
    );
  }
};

export default StepsGift;
