// import Swiper core and required modules
import { Navigation, Pagination, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { generateImage } from "../../tools/constants";
import { AnimationOnScroll } from "react-animation-on-scroll";
const Followers = ({ page }) => {
  return (

    
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={0}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: false }}
        // onSwiper={(swiper) => console.log(swiper)}
        centeredSlides={true}
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 1.2,
            spaceBetween: 0,
          },
          600: {
            slidesPerView: 1.2,
            spaceBetween: -10,
          },
          700: {
            slidesPerView: 2,
            spaceBetween: -10,
          },
          800: {
            slidesPerView: 2,
            spaceBetween: -10,
          },
          900: {
            slidesPerView: 2,
            spaceBetween: -10,
          },
          1200: {
            slidesPerView: 3.5,
          },
        }}
        // onSlideChange={() => console.log("slide change")}
      >
        {page.testimonialCard.map(function (item, i) {
          return (
            <SwiperSlide key={i}>
              {" "}
              <div
                className="clean-fam"
                animateIn="animate__slow animate__fadeInUp"
                animateOnce
              >
                <div className="logo-follow">
                  {" "}
                  <img
                    src={generateImage(item.Image.data.attributes.url)}
                    alt="#"
                  ></img>
                
                </div>
                <div className="benefit-star">
                    <img
               src="/images/ppp.svg"
                      alt="#"
                      className="w-100"
                    ></img>
                  </div>
                <div className="blue">
                  {/* <p></p> */}

                  <h1>{item.title}</h1>

                  <p>{item.description}</p>
                  {/* <p><b>{item.address}</b></p> */}
                 
                </div>
                <div className=" starTe">
                  <h1>𑁋 {item.Subscribe}</h1>
                  <p>Recommends CLEAN.FIT</p>
                  <img
                      src={generateImage(item.star.data.attributes.url)}
                      alt="#"
                      
                    ></img>
                  </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
  
  );
};
export default Followers;
