import React, { useState, useEffect } from "react";
// import "./mcFormStyles.scss";
import MailchimpSubscribe from "react-mailchimp-subscribe";
// import { useGHStContext } from "../../../utils/ContextProvider";
import InputField from "./InputField";
import { FiMail } from "react-icons/fi";
const CustomForm = ({ status, message, onValidated }) => {
  // const { modalOpen, setModalOpen } = useGHStContext();

  const [email, setEmail] = useState("");


  // const [firstName, setFirstName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    email &&
 
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
     
      });
  };

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const clearFields = () => {
    // setFirstName("");
    setEmail("");
  };

  return (
    <>
      <form
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        onSubmit={(e) => handleSubmit(e)}
      >
        <h3 className="mc__title">
          {status === "success"
            ? "Success! 😊"
            : ""}
        </h3>

        {status === "sending" && (
          <div className="mc__alert mc__alert--sending">sending...</div>
        )}
        {status === "error" && (
          <div
            className="mc__alert mc__alert--error"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            className="mc__alert mc__alert--success"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}

          <div className="mc__field-container">
    
         <div className="d-flex">
          <div className="input">
          <FiMail className="mail-icon" />
            <InputField
   
              onChangeHandler={setEmail}
              type="email"
              name="email"
              className="required email sub-email-send"
              id="mce-EMAIL"
              value={email}
              
              placeholder="your@email.com"
              isRequired
            />
               </div>
        
          <InputField
            label="subscribe"
            type="submit"
            className="subButton"
            formValues={[email]}
          />
            </div>
   
       </div>
      </form>
      {/* <iframe src="https://us14.list-manage.com/contact-form?u=1e2f83d82088156626640bfc3&form_id=e45f205abf1df9acc3992d8303d416ac"></iframe> */}
    </>
  );
};

const MailchimpForm = (props) => {
  const url = `https://cleanfitbox.us14.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default MailchimpForm;
