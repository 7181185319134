import React from "react";
import { Link } from "react-router-dom";
import { generateImage } from "../../tools/constants";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { AnimationOnScroll } from "react-animation-on-scroll";
// import Aos from "aos";
// import "aos/dist/aos.css";
const Hiw = ({ page }) => {
  // console.log(page);

  return (
    <div className="container ptb-100">
      <AnimationOnScroll
        animateIn="animate__slow animate__fadeInUp"
        animateOnce
      >
        <h1 className="main-title">{page.hiwText}</h1>
      </AnimationOnScroll>
      <AnimationOnScroll
        className="three-data  "
        animateIn="animate__slower animate__fadeInUp"
        animateOnce
      >
        {page.hiwCard.map(function (item, i) {
          // console.log(item.image.data.attributes.url);
          return (
            <>
              <div key={i}>
                <img
                  className="roundet-image"
                  src={generateImage(item.image.data.attributes.url)}
                  alt="hiw"
                />
                <h1 className="sub-title">{item.heading}</h1>
                <p>{item.description}</p>
              </div>
              <MdOutlineKeyboardArrowRight className={item.hiwClass} />
              <MdOutlineKeyboardArrowDown className={item.hiwClassMobile} />
            </>
          );
        })}
      </AnimationOnScroll>
      <AnimationOnScroll
        animateIn="animate__slower animate__fadeInUp"
        animateOnce
      >
        <Link
          className="blue-btn align-center"
          to={
            window.location.pathname.includes("homepage")
              ? "/homepage/Bonus-Box-flow"
              : "/join-now"
          }
          style={page.hiwButton.style}
        >
          {page.hiwButton.buttonText}
        </Link>
      </AnimationOnScroll>
    </div>
  );
};

export default Hiw;
