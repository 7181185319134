import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Tabs from "../Components/Layout/Tabs";
import HIW from "../Components/Layout/Hiw";
import Benefit from "../Components/Layout/Benefit";
import Ingredients from "../Components/Layout/Ingredients";
import Subscribe from "../Components/Layout/Subscribe";
import Followers from "../Components/Layout/Followers";
import InsideSwiper from "../Components/Layout/InsideSwiper";
import Features1 from "../Components/Layout/Features1";
import axios from "axios";
import { generateImage } from "../tools/constants";
import Loader from "../Loader";
import { AnimationOnScroll } from "react-animation-on-scroll";
import GifBanner from "../Components/Layout/gifBanner";
import { Helmet } from 'react-helmet';
import Build from "../Components/Layout/build";
import SubBar from "../Components/Layout/subBar";
import BlueCard from "../Components/Layout/blueCard";
import Busy from "../Components/Layout/busy";
import Customize from "../Components/Layout/customize";
import Hurry from "../Components/Layout/hurry";
// import mob from "../../src/Components/banners/mm.png";
import style from "./HomeTwo.module.css"
const HomeTwo = () => {
  const [page, setPage] = useState({
    pages: [],
    isLoaded: false,
  });
  const icon = [
    "../images/hr1.svg",
    "../images/hr2.svg",
    "../images/hr3.svg",
   

  ];
  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/homepage?populate=deep,10")
      .then((res) => {
        setPage({
          pages: res.data.data.attributes,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
      // document.title="Subscription box for healthy snacks that are ACTUALLY healthy.";
  
      // document.querySelector('meta[name="description"]').setAttribute('content', "Delicious clean snacks, superfoods, and supplements delivered monthly. Organic and all-natural. Vegan, Vegetarian, Gluten-free and Dairy-free options.");
  }, []);

  // console.log(page.pages);
  if (page.isLoaded === false) return <Loader />;
  else {
    return (
      <>
   <div className={`gif-banner-bck gif-banner-bck2 gif-banner pb-50 ${style.changeBanner}`}>
   <div className="gif-img-mob">
              <img src="../images/img2.png" className="w-100" />
              </div>
            <div className="container">
         
            <div className=" gif-display w-100  d-flex">
            
            <div className="gifBanner-content">
                <h1 className="title first-title">Busy Lifestyle. Balanced Wellness.</h1>
                <p>Clean snacks and superfoods delivered. Plus, healthy hacks to elevate your hustle.</p>
                <div className="d-flex w-100">
                  <Link
                    className="dark-btn"
                    to={
                      window.location.pathname.includes("homepage")
                        ? "/homepage/Bonus-Box-flow"
                        : "/join-now"
                    }
                    
                  >
                    Get Started
                  </Link>
                  <Link
                    className="white-btn snow-button"
                    to={
                      window.location.pathname.includes("homepage")
                        ? "/homepage/join-now-gifting"
                        : "/join-now-gifting"
                    }
                
                  
                
                  >
                    Give a Gift 🎁
                  </Link>
                </div>
              
            </div>
         
         </div>
            </div>
          
        </div>
{/*    
      <Helmet>
  <title>Subscription box for healthy snacks that are ACTUALLY healthy.</title>
  <meta name="description" content="Delicious clean snacks, superfoods, and supplements delivered monthly. Organic and all-natural. Vegan, Vegetarian, Gluten-free and Dairy-free options." />
</Helmet> */}
        <Features1 page={page.pages} />
        {/* <div className="pink-bck">
        <div className="pink-section">
          <div className="container">
            <h1>Life is stressful enough.</h1>
          <h1>Eating healthy shouldn't have to be.</h1>
          </div>
        </div>
        <div className="hurry-bck pb-50">
        <div className="container ">
       <Hurry/>
       </div>
        </div>
        </div> */}
        {/* <Benefit page={page.pages} />
        <InsideSwiper page={page.pages} /> */}
        <Build/>

        <HIW page={page.pages} /> 
        {/* <BlueCard/> */}

           <div className="pt-50 container obsessed-shadow">
           <div className=" obsessed">
          <img src="../images/badg.svg" alt="badg"/>
          <h1 className="pd-20">OBSESSED. Literally will scream it from the rooftops. This box is by far the best healthy food box I’ve ever gotten. I can’t wait to share with all my friends. My kids got to my box before I could. Thank you so much!</h1>
          <p >-Courtney O.</p>
</div>
           </div>
        <SubBar/>
        {/* <Ingredients page={page.pages} /> */}

        <AnimationOnScroll
          className="sub-container d-column pb-20 "
          animateIn="animate__slow animate__fadeInUp"
          animateOnce
        ></AnimationOnScroll>
        <div className=" pt-50 pb-50 famblue-bck">
          <h1 className="fam-title">{page.pages.testimonialFam}</h1>
          <AnimationOnScroll
            animateIn="animate__slow animate__fadeInUp"
            animateOnce
          >
            <Followers page={page.pages} />
            <Link className="blue-btn pt-20 align-center"      to={
                      window.location.pathname.includes("homepage")
                        ? "/homepage/Bonus-Box-flow"
                        : "/join-now"
                    }>
                Get a FREE Bonus box up to $150 Value
          </Link>
          </AnimationOnScroll>
        </div>

        <div className=" pt-50 container pb-50">
          <h1  className="main-title">Who CLEAN.FIT is Perfect for</h1>
          <p>CLEAN.FIT box is great for busy, health-conscious people who are short on time but don’t want to sacrifice quality and taste.</p>
          <AnimationOnScroll
            animateIn="animate__slow animate__fadeInUp"
            animateOnce
          >
            <Busy/>
          </AnimationOnScroll>
        </div>

    
     <div className=" pt-50 container">
          <h1  className="main-title">Customize Your Box</h1>
      
          <AnimationOnScroll
            animateIn="animate__slow animate__fadeInUp"
            animateOnce
          >
              <Customize/>
          </AnimationOnScroll>
        </div> 
        
     
        {/* <div className="container ptb-100">
          <AnimationOnScroll
            animateIn="animate__slower animate__fadeInUp  "
            // animateOut=""
            animateOnce
          >
            <h1 className="main-title"> {page.pages.healthyHeading}</h1>
            <p>{page.pages.healthySubHeading}</p>
          </AnimationOnScroll>
          <Tabs page={page.pages} />
        </div> */}
        <div
          className="product-banner"
          style={{
            backgroundImage: `url(
            ${generateImage(page.pages.backgroundImage.data.attributes.url)}
          )`,
          }}
        ></div>
        {/* <AnimationOnScroll
          className="label-container pt-50  d-column"
          animateIn="animate__slower animate__fadeInUp  "
          // animateOut=""
          animateOnce
        >
          <h1 className="main-title">{page.pages.readersHeading}</h1>
          <p>{page.pages.readersSubTitle}</p>
          <p>{page.pages.readersDescription}</p>
          <p> {page.pages.readersAnotherDescription}</p>
          <Link className="blue-btn pt-20 align-center" to="/join-now ">
            {page.pages.readersSubscribe.buttonText}
          </Link>
        </AnimationOnScroll> */}
        <div className="container ">
          <div className="team ">
            <AnimationOnScroll
              className="w-50"
              animateIn="animate__slower animate__fadeInLeft  "
              // animateOut=""
              animateOnce
            >
              <h1 className="main-title">{page.pages.teamHeading}</h1>
              {/* <h2 className="blue-line"></h2> */}
              <p>{page.pages.teamSubHeading}</p>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__slower animate__fadeInRight  "
              // animateOut=""
              animateOnce
            >
              <img
                className="team-img"
                src={generateImage(page.pages.teamImage.data.attributes.url)}
                alt="#"
              />
            </AnimationOnScroll>
          </div>
        </div>
        <Subscribe page={page.pages} />
      </>
    );
  }
};

export default HomeTwo;
