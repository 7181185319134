import React from "react";
import { Link } from "react-router-dom";

const Gift = () => {
  return (
    <div className="gift-banner">
      <div className="container d-flex">
        <div className="giftBanner-text w-50">
          <h1>Want to purchase a Gift or one-time box?</h1>
          <p>
            Simply select "This is a gift" during the sign-up process, and we
            will make sure your box is addressed to the gifter.
          </p>
          <p>
            Just make sure "Automatically renew this subscription" is unchecked
            (it should be unchecked by default).
          </p>
          <p>
            If you are purchasing a gift, you can leave a gift message which
            we’ll deliver with the box!
          </p>
          <p>
            Be sure to enter your billing address but THEIR shipping address in
            the correct field.
          </p>
          <Link className="blue-btn btn-swiper " to="/join-now-gifting">
             Get Started
            </Link>
        </div>
        <div className="gift-img-box">
          <img src="../images/aaa.png" alt="#" className="w-100" />
        </div>
       
      </div>
    </div>
  );
};

export default Gift;
