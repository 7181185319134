import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Tabs from "../Components/Layout/Tabs";
import HIW from "../Components/Layout/Hiw";
import Benefit from "../Components/Layout/Benefit";
import Ingredients from "../Components/Layout/Ingredients";
import Subscribe from "../Components/Layout/Subscribe";
import Followers from "../Components/Layout/Followers";
import InsideSwiper from "../Components/Layout/InsideSwiper";
import Features1 from "../Components/Layout/Features1";
import axios from "axios";
import { generateImage } from "../tools/constants";
import Loader from "../Loader";
import { AnimationOnScroll } from "react-animation-on-scroll";
import GifBanner from "../Components/Layout/gifBanner";
import { Helmet } from 'react-helmet';
import Build from "../Components/Layout/build";
import SubBar from "../Components/Layout/subBar";
import BlueCard from "../Components/Layout/blueCard";
import Busy from "../Components/Layout/busy";
import Customize from "../Components/Layout/customize";
import Hurry from "../Components/Layout/hurry";
import style from "./Home.module.css"
import Stressful from "../Components/Layout/Stressful";
import OrganicIcons from "../Components/Layout/OrganicIcons";
// import mob from "../../src/Components/banners/mm.png";
const Home = () => {
  const [page, setPage] = useState({
    pages: [],
    isLoaded: false,
  });

  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/homepage?populate=deep,10")
      .then((res) => {
        setPage({
          pages: res.data.data.attributes,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
      // document.title="Subscription box for healthy snacks that are ACTUALLY healthy.";
  
      // document.querySelector('meta[name="description"]').setAttribute('content', "Delicious clean snacks, superfoods, and supplements delivered monthly. Organic and all-natural. Vegan, Vegetarian, Gluten-free and Dairy-free options.");
  }, []);
  const icon = [
    "../images/hr1.svg",
    "../images/hr2.svg",
    "../images/hr3.svg",
   

  ];
  // console.log(page.pages);
  if (page.isLoaded === false) return <Loader />;
  else {
    return (
      <>
    <Helmet>
  <title>Subscription box for healthy snacks that are ACTUALLY healthy.</title>
  <meta name="description" content="Delicious clean snacks, superfoods, and supplements delivered monthly. Organic and all-natural. Vegan, Vegetarian, Gluten-free and Dairy-free options." />
</Helmet>
      <div className={`${style.gifBanner} gif-banner-bck gif-banner pb-50`}>
      <div className="gif-img-mob">
              <img src="./images/mobilem.png" className="w-100" />
              </div>
            <div className="container">
           
            <div className=" gif-display w-100  d-flex">
            
            <div className="gifBanner-content">
                <h1 className="title first-title">Busy Lifestyle. Balanced Wellness.</h1>
                <p>Clean snacks and superfoods delivered. Plus, healthy hacks to elevate your hustle.</p>
                <div className="d-flex w-100">
                  <Link
                    className="dark-btn"
                    to={
                      window.location.pathname.includes("homepage")
                        ? "/homepage/Bonus-Box-flow"
                        : "/join-now"
                    }
                    
                  >
                    Get Started
                  </Link>
                  <Link
                    className="white-btn snow-button"
                    to={
                      window.location.pathname.includes("homepage")
                        ? "/homepage/join-now-gifting"
                        : "/join-now-gifting"
                    }
                
                  
                
                  >
                    Give a Gift 🎁
                  </Link>
                </div>
                {/* <div className="gificon-display">
                  {icon.map((icon) => {
                         return (<img src={icon}></img>)
                  })}
                   
                </div> */}
            </div>
              
            </div>
            </div>
           
        </div>
    
        <Features1 page={page.pages} />
      
        {/* <Build/> */}
          <Stressful/>
          <HIW page={page.pages} /> 
          <OrganicIcons/>
       
        {/* <BlueCard/> */}

           <div className="pt-50 container obsessed-shadow">
           <div className=" obsessed">
          <img src="/images/ppp.svg" alt="badg"/>
      
          <h1 className="pd-20">OBSESSED. Literally will scream it from the rooftops. This box is by far the best healthy food box I’ve ever gotten. I can’t wait to share with all my friends. My kids got to my box before I could. Thank you so much!</h1>
          <p >-Courtney O.</p>
</div>
           </div>
        {/* <SubBar/> */}
        

        <AnimationOnScroll
          className="sub-container d-column pb-20 "
          animateIn="animate__slow animate__fadeInUp"
          animateOnce
        ></AnimationOnScroll>
        <div className=" pt-50 pb-50 famblue-bck">
          <h1 className="fam-title">{page.pages.testimonialFam}</h1>
          <AnimationOnScroll
            animateIn="animate__slow animate__fadeInUp"
            animateOnce
          >
            <Followers page={page.pages} />
            <Link className="blue-btn pt-20 align-center" to="/join-now ">
            Get Started with 50% OFF
          </Link>
          </AnimationOnScroll>
        </div>
{/* 
        <div className=" pt-50 container pb-50">
          <h1  className="main-title">Who CLEAN.FIT is Perfect for</h1>
          <p>CLEAN.FIT box is great for busy, health-conscious people who are short on time but don’t want to sacrifice quality and taste.</p>
          <AnimationOnScroll
            animateIn="animate__slow animate__fadeInUp"
            animateOnce
          >
            <Busy/>
          </AnimationOnScroll>
        </div> */}

    
     <div className=" pt-50 container">
          <h1  className="main-title">PICK YOUR PERFECT BOX! 😍</h1>
      
          <AnimationOnScroll
            animateIn="animate__slow animate__fadeInUp"
            animateOnce
          >
              <Customize  />
          </AnimationOnScroll>
        </div> 
{/*         
        <div
          className="product-banner"
          style={{
            backgroundImage: `url(
            ${generateImage(page.pages.backgroundImage.data.attributes.url)}
          )`,
          }}
        ></div> */}
       <div className="container ">
          <div className="team teamTwo">
            <AnimationOnScroll
              className="w-50"
              animateIn="animate__slower animate__fadeInLeft  "
              // animateOut=""
              animateOnce
            >
              <h1 className="main-title">CLEAN.FIT is on a mission to empower YOU to become the best version of yourself! </h1>
              {/* <h2 className="blue-line"></h2> */}
              <p>We're doing this by inspiring and encouraging our
#cleanfitfam to live the CLEAN.FIT life, to fuel you
to achieve your greatness and create the life of
your dreams.
</p>
<span><Link to="about" >Click here</Link> to learn more about our story.</span>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__slower animate__fadeInRight  "
              // animateOut=""
              animateOnce
            >
              <img
                className="team-img mobItem"
                src="/images/bt.png"
                alt="#"
              />
               <img
                className="team-img desItem"
                src="/images/desT.png"
                alt="#"
              />
            </AnimationOnScroll>
          </div>
        </div>
        <div className="container ">
          <div className="team">
          <AnimationOnScroll
              animateIn="animate__slower animate__fadeInRight  "
              // animateOut=""
              animateOnce
            >
              <img
                className="team-img"
                src={generateImage(page.pages.teamImage.data.attributes.url)}
                alt="#"
              />
            </AnimationOnScroll>
            <AnimationOnScroll
              className="w-50"
              animateIn="animate__slower animate__fadeInLeft  "
              // animateOut=""
              animateOnce
            >
              <h1 className="main-title">{page.pages.teamHeading}</h1>
              {/* <h2 className="blue-line"></h2> */}
              <p>If you aren't 💯 satisfied with your CLEAN.FIT box,
our committed support team is here make it right
with you. Reach out to us at hello@cleanfitbox.com
at any time!</p>
            </AnimationOnScroll>
          
          </div>
        </div>
        <Subscribe page={page.pages} />
      </>
    );
  }
};

export default Home;
