import React from "react";

const OrganicIcons = () =>{
    const organic = [
        {
            image:"/images/leaffff.svg",
            title:"Never GMO's. Often Organic.",
            description:"We read labels so you don't have to.    "
        },
        {
            image:"/images/wheat.svg",
            title:"Dietary-friendly options.",
            description:"GF, DF, Vegan, and Vegetarian choices."
        },
        {
            image:"/images/trupi.svg",
            title:"Support your fitness goals.",
            description:"Plus a free workout in every box!"
        },
        {
            image:"/images/medalja.svg",
            title:"Value-packed boxes.",
            description:"Each box has an average retail value of $65+!"
        },
    ]
    return(
        <div className="organicBck">
        <div className="container">
            
                  {organic.map(item =>{
                    return(
                        <div>
                        <img src={item.image}/>
                        <h2>{item.title}</h2>
                        <p>{item.description}</p>
                     </div>
                    )
                  })}
            </div>
        </div>
    )

}

export default OrganicIcons