import React from "react";
import { Link } from "react-router-dom";
import { generateImage } from "../../tools/constants";

const BoxImage = ({ id, slug, photoUrl, month }) => {
  return (
    <div className="box-images">
      <Link to={"/past-boxes/" + slug}>
        <img src={generateImage(photoUrl)} alt="#"></img>
        <div className="month">
          <p>{month}</p> <hr></hr>
        </div>
      </Link>
    </div>
  );
};

export default BoxImage;
