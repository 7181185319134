import axios from "axios";
import React, { useEffect, useState } from "react";

import Faqs from "../Components/Layout/Faqs";
import Subscribe from "../Components/Layout/Subscribe";
import Loader from "../Loader";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Helmet } from 'react-helmet';
const Faq = (props) => {
  const [question, setQuestion] = useState({
    questions: [],
    isLoaded: false,
  });
  const [page, setPage] = useState({
    pages: [],
    isLoaded: false,
  });
  const [toggle, setToggle] = useState(-1);

  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/homepage?populate=deep,10")
      .then((res) => {
        setPage({
          pages: res.data.data.attributes,
          isLoaded: true,
        });
      });
    axios
      .get("https://cms.cleanfitbox.com/api/faq?populate=deep,10")
      .then((res) => {
        setQuestion({
          questions: res.data.data.attributes,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  if (question.isLoaded === false || page.isLoaded === false) return <Loader />;
  else {
    return (
      <>
        <Helmet>
           <title>Subscription box for healthy snacks that are ACTUALLY healthy.</title>
           <meta name="description" content="Learn more about our founder’s story. CLEAN.FIT: delicious and clean snacks, superfoods, and supplements delivered monthly." />
         </Helmet>
        <div className="container mb-150">
          <AnimationOnScroll
            animateIn="animate__slower animate__fadeInUp  "
            // animateOut=""
            animateOnce
          >
            <h1 className="faq-header-page">{question.questions.faqHeader}</h1>
            {/* <div className="faqs-line"></div> */}
          </AnimationOnScroll>
          {question.questions.faqs.map((item, i) => {
            return (
              <Faqs
                index={i}
                isActive={toggle === i}
                setToggle={setToggle}
                question={item.question}
                answer={item.answer}
                key={i}
              />
            );
          })}
        </div>
        <Subscribe page={page.pages} />
      </>
    );
  }
};

export default Faq;
