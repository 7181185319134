import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BoxImage from "../Components/Layout/BoxImage";
import Members from "../Components/Layout/Members";
import Loader from "../Loader";
import { generateImage } from "../tools/constants";
import { Helmet } from 'react-helmet';
const Product = () => {
  const params = useParams();

  const [box, setBox] = useState({
    box: [],
    isLoaded: false,
  });
  const [boxes, setBoxes] = useState({
    boxes: [],
    isLoaded: false,
  });
  useEffect(() => {
    axios
      .get(
        `https://cms.cleanfitbox.com/api/boxes?filters[$and][0][slug][$eq]=${params.slug}&populate=deep,10`
      )
      .then((res) => {
        setBox({
          box: res.data,
          isLoaded: true,
        });

        axios
          .get("https://cms.cleanfitbox.com/api/boxes?populate=deep,10")

          .then((resBoxes) => {
            setBoxes({
              boxes: resBoxes.data.data,
              isLoaded: true,
            });
          });
      })
      .catch((err) => console.log);
  }, [params.slug]);
  // console.log("productboxes", params.id);

  if (box.isLoaded === false) return <Loader />;
  else {
    return (
      <>
       <Helmet>
           <title>Subscription box for healthy snacks that are ACTUALLY healthy.</title>
           <meta name="description" content="Learn more about our founder’s story. CLEAN.FIT: delicious and clean snacks, superfoods, and supplements delivered monthly." />
         </Helmet>
        <div className="container d-flex product-display">
          <div className="product-name w-50">
            <h1 className=" pd-20 product-name">
              {box.box.data[0].attributes.bannerHeading}
              <span>{box.box.data[0].attributes.month} Box</span>
            </h1>
            <p className="pd-20">
              <div
                dangerouslySetInnerHTML={{
                  __html: box.box.data[0].attributes.bannerSubheading,
                }}
              ></div>
            </p>
            <div className="d-flex w-100">
              <Link
                className="dark-btn"
                to="/join-now"
                style={box.box.data[0].attributes.giftBannerButton.style}
              >
                {box.box.data[0].attributes.subscribeBannerButton.buttonText}
              </Link>
              <Link
                className="gift-btn"
                to="/join-now-gifting"
                style={box.box.data[0].attributes.giftBannerButton.style}
              >
                {box.box.data[0].attributes.giftBannerButton.buttonText}
              </Link>
            </div>
          </div>

          <div className=" product-img product-width">
            {" "}
            <img
              src={generateImage(
                box.box.data[0].attributes.mainBannerImage.data.attributes.url
              )}
              alt="#"
            ></img>
          </div>
        </div>

        <Members data={box.box.data[0].attributes} />
        <div className="container boxes">
          <h1>{box.box.data[0].attributes.otherBoxes} </h1>
          <div className="d-flex box-wrap">
            {boxes.boxes
              .filter((e) => e.id !== box.box.data.id)
              .slice(Math.max(boxes.boxes.length -3 , 0 ))
              .sort()
              .reverse()
              .map((e, i) => (
                <BoxImage
                  id={e.id}
                  slug={e.attributes.slug}
                  month={e.attributes.month}
                  photoUrl={e.attributes.featuredImage.data.attributes.url}
                  key={i}
                />
              ))}
          </div>
        </div>
        <div className="w-100  lightgreen">
          <div className="container">
            <h1>Ready to Join?</h1>
            <p>Get surprised each month with a new CLEAN.FIT Box! 🤩</p>
            <div className="w-100 product-btn">
              <Link to="/join-now">Get Started</Link>
              <Link to="/join-now-gifting">Give a Gift</Link>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Product;
