// import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
// import Loader from "../../Loader";
// import Subscribe from "./Subscribe";
import { AnimationOnScroll } from "react-animation-on-scroll";
const Faqs = (props) => {
  // console.log(props.isActive);
  return (
    <>
      <AnimationOnScroll
        className="faq  "
        animateIn="animate__slower animate__fadeInUp  "
        // animateOut=""
        animateOnce
      >
        <div
          className="faq-display"
          onClick={() =>
            props.isActive ? props.setToggle(-1) : props.setToggle(props.index)
          }
        >
          <h1>{props.question}</h1>
          {props.isActive ? (
            <MdKeyboardArrowUp className="toggle-icon" />
          ) : (
            <MdOutlineKeyboardArrowDown className="toggle-icon" />
          )}
        </div>

        {props.isActive && (
          <p className={props.isActive ? "transition" : "transition-fade-out"}>
            <div dangerouslySetInnerHTML={{ __html: props.answer }}></div>
          </p>
        )}
      </AnimationOnScroll>
    </>
  );
};

export default Faqs;
