import React from "react";
import { generateImage } from "../../tools/constants";

const Groupmobile = ({ page }) => {
  console.log(page);

  return (
    <div className=" w-100 mobile-group">
      {page.groupMobile.map(function (item, i) {
        return (
          <a href="#" className="group-link w-100 " key={i}>
            <div className=" group-display">
              <p>{item.description}</p>
              <img
                className="group-png"
                src={generateImage(item.image.data.attributes.url)}
                alt="#"
              ></img>
            </div>
          </a>
        );
      })}
    </div>
  );
};

export default Groupmobile;
