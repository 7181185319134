import axios from "axios";
import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import Steps from "../Components/Layout/Steps";
import About from "../Pages/About";
import Blog from "../Pages/Blog";
import Business from "../Pages/Business";
import Causes from "../Pages/Causes";
import Coaching from "../Pages/Coaching";
import Faq from "../Pages/Faq";
import "animate.css";
import Home from "../Pages/Home";
import PastBoxes from "../Pages/PastBoxes";
import Product from "../Pages/Product";

import Summer from "../Pages/Summer";
import ScrollToTop from "../ScrollToTop";
import List from "../Pages/List";
import Policy from "../Pages/Policy";
import Contact from "../Pages/Contact";
import Errormsg from "../Pages/Errormsg";
import StepsGift from "../Components/Layout/StepsGift";
import AllArticles from "../Components/articles/AllArticles";
import Workout from "../Components/articles/Workout";
import Recipe from "../Components/articles/Recipe";
import Lifestyle from "../Components/articles/Lifestyle";
import Healthy from "../Components/articles/Healthy";
import ArticlesTb from "../Components/Layout/ArticlesTb";
import Mailchimp from "../Pages/Mailchimp";
import homepage from "../Pages/homeTwo";
import StepsTwo from "../Components/Layout/stepsTwo";
import HomeTwo from "../Pages/homeTwo";

function RouteConfig() {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/homepage" element={<HomeTwo />} />
        <Route path="/past-boxes" element={<PastBoxes />} />
        <Route path="/homepage/past-boxes" element={<PastBoxes />} />
        <Route path="/past-boxes/:slug" element={<Product />} />
        <Route path="/join-now" element={<Steps />} />
        <Route path="/homepage/Bonus-Box-flow" element={<StepsTwo />} />
        <Route path="/about" element={<About />} />
        <Route path="/homepage/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/homepage/faq" element={<Faq />} />
        <Route path="/singleBlog/:slug" element={<Summer />} />
        <Route path="/coaching" element={<Coaching />} />
        <Route path="/business" element={<Business />} />
        <Route path="/homepage/business" element={<Business />} />
        <Route path="/causes" element={<Causes />} />
        <Route path="/homepage/causes" element={<Causes />} />
        <Route path="/the-never-list" element={<List />} />
        <Route path="/homepage/the-never-list" element={<List />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/homepage/contact-us" element={<Contact />} />
        <Route path="/join-now-gifting" element={<StepsGift />} />
        <Route path="/homepage/join-now-gifting" element={<StepsGift />} />
        <Route path="/articles/:name" element={<ArticlesTb />} />
        <Route path="/*" element={<Errormsg />} />
        <Route path="/testemail" element={<Mailchimp />} />
      </Routes>
    </ScrollToTop>
  );
}

export default RouteConfig;
