import React from "react";



const Busy = () => {
    const busy = [
        {
            images:"../images/1.svg" ,
            description:"Busy Professionals"
        },
        {
            images:"../images/2.svg" ,
            description:"On-the-go Parents"
        },
        {
            images:"../images/3.svg" ,
            description:"Road Warriors"
        },
        {
            images:"../images/4.svg" ,
            description:"Active Kiddos"
        },
        {
            images:"../images/5.svg" ,
            description:"Jet Setters "
        },
        {
            images:"../images/6.svg" ,
            description:"Health Nuts"
        },
        {
            images:"../images/7.svg" ,
            description:"College Students"
        },
        {
            images:"../images/8.svg" ,
            description:"Entrepreneurs"
        },
        {
            images:"../images/9.svg" ,
            description:"Foodies"
        },
    ]
    return (
        <div className="busy-display pt-50">
            {busy.map(item =>{
              return (
                <div>
                <img src={item.images} alt="#"/>
                <p>{item.description}</p>
            </div>
              )
            })}
            
        </div>
    )
}

export default Busy