import axios from "axios";
import React, { useEffect, useState } from "react";
import Faqs from "../Components/Layout/Faqs";
import Loader from "../Loader";
import { generateImage } from "../tools/constants";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Helmet } from 'react-helmet';
const List = () => {
  const [toggle, setToggle] = useState(-1);

  const [question, setQuestion] = useState({
    questions: [],
    isLoaded: false,
  });
  const [list, setList] = useState({
    lists: [],
    isLoaded: false,
  });
  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/list?populate=deep,10")
      .then((res) => {
        setList({
          lists: res.data.data.attributes,
          isLoaded: true,
        });
      });

    axios
      .get("https://cms.cleanfitbox.com/api/faq?populate=deep,10")
      .then((res) => {
        setQuestion({
          questions: res.data.data.attributes,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
      // document.title=" Subscription box for healthy snacks that are ACTUALLY healthy.";
      // document.querySelector('meta[name="description"]').setAttribute('content', "See what’s NOT inside CLEAN.FIT boxes! We read labels for you. Delicious, clean snacks and superfoods delivered monthly. Organic, non-GMO, and all-natural.");
     
  }, []);

  if (question.isLoaded === false || list.isLoaded === false) return <Loader />;
  else {
    return (
      <>
        <Helmet>
           <title>Subscription box for healthy snacks that are ACTUALLY healthy.</title>
           <meta name="description" content="See what’s NOT inside CLEAN.FIT boxes! We read labels for you. Delicious, clean snacks and superfoods delivered monthly. Organic, non-GMO, and all-natural." />
         </Helmet>
        <div className="list-bck">
          <AnimationOnScroll
            className="container pbt-100 mean"
            animateIn="animate__slower animate__fadeInLeft  "
            // animateOut=""
            animateOnce
          >
            <h1>{list.lists.title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: list.lists.description,
              }}
            ></div>
            {/* <p>
              Any beauty brand can call themselves clean—but can they back it
              up? We can, and it all starts with safety.
            </p>
            <p>
              Since our launch in 2013, we’ve set the highest bar for safety in
              the beauty industry. It starts with our The Never List™: 1,800
              questionable ingredients we never use in our formulations. Then,
              we go above and beyond to screen and assess every ingredient we
              use against our strict safety standards. What’s left is an
              ever-evolving toolbox of clean, sustainable ingredients that we
              use to create innovative formulas that deliver results—safely.
            </p>
            <p>
              While all of our ingredients are sourced with a higher standard of
              safety and quality in mind, it is well recognized — and accepted
              by regulatory authorities around the world — that incidental,
              trace levels of a chemical may inadvertently be introduced in a
              cosmetic product due to the complexities of the supply chain and
              manufacturing process. At Beautycounter, we work incredibly hard
              to minimize — but unfortunately, can’t eliminate — the potential
              that a product may contain trace levels of a chemical from The
              Never List™. To learn how safety is our north star, read more
              below.
            </p> */}
          </AnimationOnScroll>
        </div>
        <div className="container">
          <AnimationOnScroll
            className="w-20 benefit-data list-benefit "
            animateIn="animate__slower animate__fadeInUp "
            // animateOut=""
            animateOnce
          >
            {list.lists.listIcon.map(function (item, i) {
              return (
                <div key={i}>
                  <img
                    src={generateImage(item.image.data.attributes.url)}
                    alt="benefit-group"
                  />
                </div>
              );
            })}
          </AnimationOnScroll>
          <AnimationOnScroll
            className="pbt-100 ingredients"
            animateIn="animate__slower animate__fadeInUp "
            // animateOut=""
            animateOnce
          >
            <h1>{list.lists.ingredientsHeading}</h1>
            <p>{list.lists.ingredientsSubHeading}</p>
          </AnimationOnScroll>
        </div>
        <div className="faq-list">
          <div className="container">
            {" "}
            {question.questions.listFaq.map((item, i) => {
              // console.log(i);
              // console.log(item);
              return (
                <Faqs
                  index={i}
                  isActive={toggle === i}
                  setToggle={setToggle}
                  question={item.question}
                  answer={item.answer}
                  key={i}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }
};

export default List;
