import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";


const Customize = (props) =>{
    const [selectedBox, setSelectedBox] = useState("super-snacker");
    const [dataArray, setDataArray] = useState([]);
  
    const handleBoxClick = (boxName) => {
      setSelectedBox(boxName);
    };
    console.log(selectedBox)
  
    const navigate = useNavigate();

    const handleContinueCustomizing = () => {
      if (selectedBox) {
        setDataArray((prevArray) => [...prevArray, selectedBox]);
      }
      navigate("/join-now", { state: { dataArray } });
    };
  console.log("dataArray",dataArray)
    return (
      <div className="container customize">
        <div className="stepBox-display">
          <div
            className={`super-box ${selectedBox === 'super-snacker' ? 'selected' : ''}`}
            onClick={() => handleBoxClick('super-snacker')}
          >
            <img src="/images/pd4.png" className="pd1"/>
            <img src="/images/pd3.png" className="pd2"/>
            <div    className="hover-recommand2" >
            <p>Most Popular</p>
            </div>
            <img src="../images/100.png" alt="#" />
            <div className="customize-content">
              <p>
                <span>#SuperSnacker</span>
                <p className="customize-pink">Get 2x the goodies!</p>
                {/* <span>Average box value: $100+</span> */}
                <h6 className="ptb-20">
                <b> starts from $56</b>/mo
                </h6>
                <p>Each monthly box contains 20+ premium, hand-curated items
</p>
              </p>
            </div>
    
          </div>
  
          <div
            className={`super-box1 blueColor ${selectedBox === 'regular' ? 'selected' : ''}`}
            onClick={() => handleBoxClick('regular')}
          >
          <img src="/images/pd1.png" className="pd3"/>
            <img src="/images/pd2.png" className="pd4"/>
            <img src="../images/60.png" alt="#" />
            <div className="customize-content">
              <p>
                <span>Regular</span>
                <p> </p>
                {/* <span>Average box value: $60+</span> */}
                <h6  className="ptb-20">
                <b>starts from $37</b>/mo
                 
                </h6>
                <p>Each monthly box contains 10+ premium, hand-curated items</p>
              </p>
            </div>

          </div>
        </div>
        <div className="step6-btns">
        <Link
          className="blue-btn pt-20 align-center"
          to={
            window.location.pathname.includes("homepage")
              ? "/homepage/Bonus-Box-flow"
              : `/join-now?offer=${selectedBox}`
          }
      
          onClick={handleContinueCustomizing}
        >
          Continue Customizing!
        </Link>
      </div>
      </div>
    );
  }

export default Customize;