import React, { useState } from "react";
import { generateImage } from "../../tools/constants";
import ScrollToTop from "../../../src/ScrollToTop";
import gtag from 'ga-gtag';
import { click } from "@testing-library/user-event/dist/click";
const StepFive = (props) => {
  const [active, setActive] = useState(0);
  const [message, setMessage] = useState("");
  const [pickPreferenceTitle, setPickPreferenceTitle] = useState(
    props.data.pickPreferenceTitle
  );
  const [pickPreference, setPickPreference] = useState(
    props.data.pickPreference
  );
  // console.log(props.page.step5);

    const handleSubmit = (e) => {
      e.preventDefault();
      // console.log(pickPreference);
      if (validation(pickPreference)){
        props.next("pickPreference", pickPreference);
      
        gtag('event', "stepFive", {
          event_category: "signupFlow",
          event_label:pickPreference,
        });
      }
      else setMessage("Error");
       if (validation(pickPreferenceTitle))
        props.handlePickStep("pickPreferenceTitle", pickPreferenceTitle);
      else setMessage("Error");
    };
  
 
  const radioChange = (e) => {
    //Nese e klikon ndonje tjeter masi e ka kliku None for Me, me i hek None for me.
    if (pickPreference.includes("146263")) setPickPreference([]);
    if (pickPreferenceTitle.includes("146263")) setPickPreferenceTitle([]);


    if (e.target.value !== "146263") {
      //REMOVE NONE FOR ME
      if (e.target.checked) {
        setPickPreference((current) => [...current, e.target.value]);
        console.log("test", e.target.checked)
        setPickPreferenceTitle((current) => [...current, e.target.id]);
      } else {
        setPickPreference(
          pickPreference.filter((val, i) => val !== e.target.value),
          
        );
        setPickPreferenceTitle(
          pickPreferenceTitle.filter((val, i) => val !== e.target.id)
          
        );
        
      }
    } else setPickPreference([e.target.value]);
  };



  

  const validation = (val) => {
    var group = document.radioForm.preference;
    // console.log("preference", document.radioForm.preference);
    for (var i = 0; i < group.length; i++) {
      if (group[i].checked) break;
    }
    if (i === group.length) {
      alert("No radio button is checked");
      return false;
    } else {
      return true;
    }
  };

  return (
    <ScrollToTop>
    <div className=" container">
      <h3 className="step-header">
        Alrighty, <span className="step-name">{props.data.firstName}</span>. Any
        dietary preferences we should know about? 🤔
      </h3>

      <form onSubmit={handleSubmit} name="radioForm">
        <div className="stepBox-display">
          {props.page.step5.map((item, i) => (
            
            <label
              htmlFor={item.title}
              onClick={() =>  console.log("aa",item.subblyid)}
              className={
               
                pickPreference.includes(item.subblyid)
                  ? "step-five box-active"
                  : "step-five"
              }
              key={item.inputId}
            >
              <input
                type="checkbox"
                id={item.title}
                className="hideInput"
                onChange={radioChange}
                name="preference"
                value={item.subblyid}
                
              />
              <img
                src={generateImage(item.image.data.attributes.url)}
                alt="kutia"
              />
              <p>{item.title}</p>
            </label>
          ))}
        </div>
        <div className="mtb-20">
          <input className="step-button" type="submit" value="Next"  />
        </div>
      </form>
      <div className="go-back" onClick={props.prev}>
        <p>Go Back</p>
      </div>
    </div>
    </ScrollToTop>
  );
};

export default StepFive;
