import React from "react";
import { Link } from "react-router-dom";


const SubBar = () => {
    const firstBox = [
        {
            
            content: (
                <span>
                    Every item goes through a strict vetting process{' '}
                    <Link to="/the-never-list">(see our Never List here)</Link>
                </span>
            ),
        },
        {
          
            content:"Each snack is thoughtfully hand-curated by our wellness experts to maximize health and taste",
        },
        {
           
            content:"Our items contain high-quality superfoods that promote wellness",
        },
        {
          
            content:"We partner with brands who have sustainable, eco-friendly practices",
        },
        {
        
            content:"We read and scrutinize every label so you don’t have to (you’re busy enough!)",
        },
    ]

    const secondbox = [
        {
           
            content:"Have NO vetting process and low quality standards",
        },
        {
           
            content:"Snacks are made by companies that spend lots of money on marketing, but don’t care about quality",
        },
        {
          
            content:"Contain shady ingredients you cannot pronounce",
        },
        {
          
            content:"Have no focus on long-term health for humans or the planet",
        },
        {
        
            content:"Cause you to waste your precious time researching and looking for decent snacks",
        },
    ]
    return(
        <div className="container pt-50 pb-50">
             <h1 className="main-title">Don’t Settle for Subpar Snacks.</h1>
             <div className="sub-arrows">
                
             <img src="../images/blue.png" ></img> <img src="../images/red.png" ></img>
             </div>
             <div className="sub-display">
             <img src="../images/blue.png" className="mobile-sub"/>
                <div className="sub-firstbox w-45">
                    {firstBox.map((item) => {
                        return (
                            <div>
                              <img src="../images/icon.svg" alt="icon"/>
                               <p>{item.content}</p>
                            </div>
                        )
                    })}
                   
                </div>
                <img src="../images/red.png" className="mobile-sub" />
                <div className=" w-45 sub-secondbox">
                    {secondbox.map((item) => {
                        return (
                            <div>
                              <img src="../images/x.svg" alt="icon"/>
                               <p>{item.content}</p>
                            </div>
                        )
                    })}
                   
                </div>
             </div>
        </div>
    )
}

export default SubBar;