import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import axios from "axios";
const Policy = () => {
  const [policy, setPolicy] = useState({
    policy: [],
    isLoaded: false,
  });

  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/policy?populate=deep,10")
      .then((res) => {
        setPolicy({
          policy: res.data.data.attributes,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  // console.log(policy.policy);
  if (policy.isLoaded === false) return <Loader />;
  else {
    return (
      <div className="policy container">
        <div className="ptb-100">
          <h1>{policy.policy.Title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: policy.policy.policyDescription,
            }}
          ></div>
        </div>
        <div className="ptb-100">
          <h1>{policy.policy.secondTitle}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: policy.policy.terms,
            }}
          ></div>
        </div>
      </div>
    );
  }
};

export default Policy;
