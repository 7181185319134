import React, { useState } from "react";
import ScrollToTop from "../../../src/ScrollToTop";
// import ReactPixel from "react-facebook-pixel";
import gtag from 'ga-gtag';
const StepSixGift = (props) => {
  // const [message, setMessage] = useState("");
  const [recommendation, setRecommendation] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validation(recommendation)){
      props.next("recommendation", recommendation);
      gtag('event', "stepSixGift", {
        event_category: "signupFlow",
        event_label:recommendation,
      });
    }
  };

  const firstNameChange = (e) => {
    // ReactPixel.track("AddToCart");

    validation(e.target.id);
    setRecommendation(e.target.id);
  };

  const validation = (val) => {
    return true;
  };
  return (
    <ScrollToTop>
      <div className="container">
        <h3 className="step-header-six">
          {props.data.pickedBox !== "boxGift1" ? (
            <h3 className="step-header-six">
              Awesome! We think that the{" "}
              <p className="step-name">
                {props.data.pickPreferenceTitle.join(", ")}
              </p>{" "}
              #SuperSnacker box is PERFECT for{" "}
              <span className="step-name">{props.data.recipientName}</span>
            </h3>
          ) : (
            <h3 className="step-header-six">
              Awesome! We think that the{" "}
              <p className="step-name">
                {props.data.pickPreferenceTitle.join(", ")}
              </p>{" "}
              Regular Box is PERFECT for{" "}
              <span className="step-name">{props.data.recipientName}</span>
            </h3>
          )}
        </h3>

        <div className="stepBox-display">
          <div className="super-box ssupper">
            {props.data.pickedBox !== "boxGift1" ? (
              <div className="hover-recommand">
                <p>Our Recommendation</p>
              </div>
            ) : null}
            <img src="../images/100.png" alt="#"></img>
            <div>
              <p>
                <span>Get 2x the goodies: </span>
                <p>each monthly box contains 20+ premium, hand-curated items</p>
                {/* <span>Average box value: $100+</span> */}
                <h6>starts from $58/mo</h6>
              </p>
            </div>
            <form className="mtb-20" onSubmit={handleSubmit}>
              <input
                type="submit"
                name="button"
                id="recommendation2"
                value="Continue"
                onClick={firstNameChange}
              ></input>
            </form>
          </div>

          <div className="super-box1 ssupper blueColor">
            {props.data.pickedBox === "boxGift1" ? (
              <div className="hover-recommand">
                <p>Our Recommendation</p>
              </div>
            ) : null}
            <img src="../images/60.png" alt="#"></img>
            <div>
              <p>
                <span>Regular Size</span>
                <p>Each monthly box contains 10+ premium, hand-curated items</p>
                {/* <span>Average box value: $60+</span> */}
                <h6>starts from $36/mo</h6>
              </p>
            </div>
            <form className="mtb-20" onSubmit={handleSubmit}>
              <input
                type="submit"
                name="button"
                id="recommendation1"
                value="Continue"
                onClick={firstNameChange}
              ></input>
            </form>
          </div>
        </div>
        <div className="go-back" onClick={props.prev}>
          <p>Go Back</p>
        </div>
      </div>
    </ScrollToTop>
  );
};

export default StepSixGift;
