import React from "react";
import { generateImage } from "../../tools/constants";
import ScrollToTop from "../../../src/ScrollToTop";
import gtag from 'ga-gtag';
const StepThree = (props) => {
  // console.log(props);

  const radioChange = (e) => {
    // setBoxValue(e.target.value);
    props.next("pickedBox", e.target.value);
    gtag('event', "stepThree", {
      event_category: "signupFlow",
      event_label:e.target.value,
    });
  };

  return (
    <ScrollToTop>
    <div className=" container">
      <h3 className="step-header">
        Great! And now for an even more important question… What type of snacker
        are you, <span className="step-name">{props.data.firstName}</span>?!
      </h3>

      <form
        className="stepBox-display"
        name="radioForm"
        style={{ display: "flex", flexDirection: "row" }}
      >
        {props.page.step3.slice(0, 3).map(function (item) {
          return (
            <label
              htmlFor={item.inputId}
              className="snack-box "
              key={item.value}
            >
              <input
                type="radio"
                id={item.inputId}
                className="hideInput"
                name="pickedBox"
                value={item.subblyid}
                onChange={radioChange}
              />

              <div>
                <img
                  src={generateImage(item.image.data.attributes.url)}
                  alt="#"
                />
              </div>
              <p>{item.title}</p>
            </label>
          );
        })}
      </form>
      <div className="go-back" onClick={props.prev}>
        <p>Go Back</p>
      </div>
    </div>
    </ScrollToTop>
  );
};

export default StepThree;
