import React from "react";
import { generateImage } from "../../tools/constants";
import bell from "../../assets/Bell.svg";
import gtag from 'ga-gtag';
import ScrollToTop from "../../../src/ScrollToTop";
const StepSeven = (props) => {
  const goToCart = (productId) => {
    var optionsArray = `https://checkout.cleanfitbox.com/checkout/buy/${productId}?`;
    var pickedBox = props.data.pickedBox;
    var pickPreference = props.data.pickPreference;
    var picked = props.data.picked;
    var coupon = '';

    if (productId === "226812") {
      coupon = '';
    }else if (productId === "226826"){
      coupon = '';
    }else if(productId === "226818"){
      coupon = "50_OFF_FIRST_BOX"
    }else if(productId === "226822"){
      coupon = "50_OFF_FIRST_BOX"
    }
    else if(productId === "226831"){
      coupon = "50_OFF_FIRST_BOX"
    } else if(productId === "226834"){
      coupon = "50_OFF_FIRST_BOX"
    }
    else if(productId === "226828"){
      coupon = "25_OFF_FIRST_BOX"
    }  else if(productId === "226815"){
      coupon = "25_OFF_FIRST_BOX"
    }
    else{
      coupon = '50_OFF_FIRST_BOX';
    }
  
    for (var i = 0; i < props.data.picked.length; i++) {
      optionsArray += `options[questions][34268][questionId]=34268&options[questions][34268][type]=multiple&options[questions][34268][answers][${i}]=${picked[i]}&`;
    }
  
    optionsArray += `options[questions][34269][questionId]=34269&options[questions][34269][type]=select&options[questions][34269][answers]=${pickedBox}&`;
  
    for (var i = 0; i < props.data.pickPreference.length; i++) {
      optionsArray += `options[questions][33656][questionId]=33656&options[questions][33656][type]=multiple&options[questions][33656][answers][${i}]=${pickPreference[i]}&`;
    }
  
    // Add coupon code to link if applicable
    if (coupon) {
      optionsArray += `&coupon=${coupon}`;
    }
  
    console.log("id",productId);
    window.open(optionsArray, '_parent');
  };
  
  

  return (
    <ScrollToTop>
      <div className=" container ">
        <h3 className="step-header">
          Alrighty, now we’re cookin’! (Or should we say, snackin’ )
        </h3>
       
        {props.data.recommendation === "recommendation2"
            ? <div className="average-box-value"> <img src={bell}></img><h4>Average box value, $100+!</h4></div> : <div className="average-box-value"> <img src={bell}></img><h4>Average box value, $65+!</h4></div>  }
        <div className="stepBox-display">
        
          {props.data.recommendation === "recommendation2"
            ? props.page.stepSevenBox.map((item) => {
                console.log(item);
                return (
                  <div
                    className="price-box"
                    key={item.value}
                    onClick={() => {
                      goToCart(item.subblyid);
                      gtag('event', "stepSeven", {
                        event_category: "signupFlow",
                        event_label:item.value,
                      });
                    }}
                  >
                    {item.recommended.data ? (
                      <img
                        className="recommended"
                        src={generateImage(
                          item.recommended.data.attributes.url
                        )}
                        alt="#"
                      ></img>
                    ) : (
                      ""
                    )}
                    <input
                      type="radio"
                      id={item.boxID}
                      className="hideInput"
                      name={item.month}
                      value={item.month}
                      // onChange={radioChange}
                    />
                    <label htmlFor={item.boxID}>
                      <img
                        src={generateImage(item.image.data.attributes.url)}
                        alt="#"
                      ></img>

                      <div>
                        <div className="four-box">
                          <h3>{item.month}</h3>

                          <p>{item.price}</p>

                          <p className={`free ${item.boxID} `}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.free,
                              }}
                            ></div>
                          </p>
                        </div>

                        <span>{item.shipping}</span>
                      </div>
                      <div className={item.class}>
                        <p>{item.discount}</p>
                      </div>
                    </label>
                  </div>
                );
              })
            : props.data.recommendation === "recommendation1"
            ? props.page.stepRegularBox.map((item) => {
                return (
                  <div
                    className="price-box"
                    key={item.value}
                    onClick={() => {
                      goToCart(item.subblyid);
                    }}
                  >
                    {item.recommended.data ? (
                      <img
                        className="recommended"
                        src={generateImage(
                          item.recommended.data.attributes.url
                        )}
                        alt="#"
                      ></img>
                    ) : (
                      ""
                    )}
                    <input
                      type="radio"
                      id={item.boxID}
                      className="hideInput"
                      name={item.month}
                      value={item.month}
                      // onChange={radioChange}
                    />
                    <label htmlFor={item.boxID}>
                      <img
                        src={generateImage(item.image.data.attributes.url)}
                        alt="#"
                      ></img>

                      <div>
                        <div className="four-box">
                          <h3>{item.month}</h3>

                          <p>{item.price}</p>

                          <p className={`free ${item.boxID} `}>
                            {" "}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.free,
                              }}
                            ></div>
                          </p>
                        </div>

                        <span>{item.shipping}</span>
                      </div>
                      <div className={item.class}>
                        <p>{item.discount}</p>
                      </div>
                    </label>
                  </div>
                );
              })
            : ""}

          <p className="stepSeven-description">
         {props.page.stepSevenDescription}
          </p>
        </div>
        <div className="go-back" onClick={props.prev}>
          <p>Go Back</p>
        </div>
      </div>
    </ScrollToTop>
  );
};

export default StepSeven;
