import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Articles from "../Components/Layout/Articles";
import Relevant from "../Components/Layout/Relevant";
import Loader from "../Loader";
import { generateImage } from "../tools/constants";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Helmet } from 'react-helmet';
import moment from "moment";
const Blog = (id) => {
  const [singleBlog, setSingleBlog] = useState({
    singleBlog: [],
    isLoaded: false,
  });
  const [blog, setBlog] = useState({
    blogs: [],
    isLoaded: false,
  });

  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/single-blog?populate=deep,10")

      .then((resPages) => {
        axios
          .get(
            "https://cms.cleanfitbox.com/api/blogs?populate=deep,10&pagination[start]=0&pagination[limit]=-1"
          )

          .then((resBoxes) => {
            setSingleBlog({
              singleBlog: resPages.data.data.attributes,
              isLoaded: true,
            });
            setBlog({
              blogs: resBoxes.data.data,
              isLoaded: true,
            });
          });
      })
      .catch((err) => console.log(err));
    // document.title =
    //   "Subscription box for healthy snacks that are ACTUALLY healthy.";
    // document.getElementsByTagName("META")[2].content =
    //   "Wellness tips, at-home workouts, self-care secrets, and easy, quick healthy recipes: CLEAN.FIT helps fuel you to be the best version of yourself.";
  }, []);
  console.log("blog.blogs",blog.blogs);

  const blogButton = (item) => {
    return item.Category === "workout" ? item.Category : item[0].id;
  };

  if (singleBlog.isLoaded === false || blog.isLoaded === false)
    return <Loader />;
  else {
    return (
      <>
        <Helmet>
           <title>Subscription box for healthy snacks that are ACTUALLY healthy.</title>
           <meta name="description" content="Wellness tips, at-home workouts, self-care secrets, and easy, quick healthy recipes: CLEAN.FIT helps fuel you to be the best version of yourself." />
         </Helmet>
      <div className="container">
        <AnimationOnScroll
          className="d-flex w-100 blog-border"
          animateIn="animate__slower animate__fadeIn"
          // animateOut=""
          animateOnce
        >
          {blog.blogs
              .slice(-1)
            .map((e, i) => {
   

              return (
                <>
                  <div className="about-title" key={i}>
                    <h1>{e.attributes.blogBox.title}</h1>
                    <p>
                      {e.attributes.descriptionBox.substring(
                        100,
                        e.attributes.descriptionBox
                      ) + "..."}
                    </p>
                    <Link to={"/singleBlog/" + e.attributes.slug}>
                      <p className="about-btn">
                       Read More
                      </p>
                    </Link>
                  </div>
                  <div className="blog-banner">
                    <img
                      src={generateImage(
                        e.attributes.blogBox.image.data.attributes.url
                      )}
                      alt="#"
                    ></img>
                  </div>
                </>
              );
            })}
        </AnimationOnScroll>
        <AnimationOnScroll
          className="d-flex workout-display w-100"
          animateIn="animate__slower animate__fadeInUp"
          // animateOut=""
          animateOnce
        >
          {blog.blogs
           .slice(-3, -1)
            .map((e, i) => {
              return (
                <div className="w-45" key={i}>
                  <div>
                    <img
                      src={generateImage(
                        e.attributes.blogBox.image.data.attributes.url
                      )}
                      alt="#"
                    ></img>
                  </div>
                  <div className="workout-width">
                    <h1>
                      {e.attributes.blogBox.title.substring(
                        50,
                        e.attributes.blogBox.title
                      ) + "..."}
                    </h1>
                    <p className="bigblog-description">
                      {e.attributes.descriptionBox.substring(
                        100,
                        e.attributes.descriptionBox
                      ) + "..."}
                    </p>

                    <Link to={"/singleBlog/" + e.attributes.slug}>
                      <p className="about-btn">
                        {e.attributes.blogBox.button.buttonText}
                      </p>
                    </Link>
                  </div>
                </div>
              );
            })}
        </AnimationOnScroll>
        <Articles />
        <div className="relevant-heading">
          <h1>{singleBlog.singleBlog.relevantHeading}</h1>
        </div>
        <div className="d-flex relevant-wrap  w-100">
          {blog.blogs
            .slice(Math.max(blog.blogs.length - 6, 0))
            .sort()
            .reverse()
            .map((e, i) => {
              const date = e.attributes.createdAt;
              var now = moment
                .utc(date, "YYYY-MM-DDTHH:mm:ssZ")
                .format("YYYY-MM-DD");
                console.log("aaaa", e);
              return (
                <Relevant
                  id={e.id}
                  photoUrl={e.attributes.blogBox.image.data.attributes.url}
                  title={e.attributes.blogBox.title}
                  slug={e.attributes.slug}
                  hashtag={e.attributes.Category}
                  data={now}
                  key={i}
                />
              );
            })}
        </div>
        <AnimationOnScroll
          className="d-flex w-100 blog-border mb-100 "
          animateIn="animate__slower animate__fadeInUp"
          // animateOut=""
          animateOnce
        >
          {blog.blogs
              .slice(-4, -3)
            .map((e, i) => {
              // console.log(e);
              return (
                <>
                  <div className="blog-banner" key={i}>
                    <img
                      src={generateImage(
                        e.attributes.blogBox.image.data.attributes.url
                      )}
                      alt="#"
                    ></img>
                  </div>
                  <div className="about-title">
                    <h1>{e.attributes.blogBox.title}</h1>
                    <p>
                      {e.attributes.descriptionBox.substring(
                        100,
                        e.attributes.descriptionBox
                      ) + "..."}
                    </p>
                    <Link to={"/singleBlog/" + e.attributes.slug}>
                      <p className="about-btn">
                        {e.attributes.blogBox.button.buttonText}
                      </p>
                    </Link>
                  </div>
                </>
              );
            })}
        </AnimationOnScroll>
      </div></>
    );
  }
};

export default Blog;
