import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Build = () => {
  const box = [
    {
      title: "Only Premium, High-Quality Ingredients",
      content: "Our products are totally clean, often organic, and always non-GMO. We read the labels so you don’t have to!",
      image: "../images/build1.png",
      class: "build-display"
    },
    {
      title: "Customizable by Dietary Preferences",
      content: "Choose the box that suits you: we offer Gluten-Free, Dairy-Free, Vegan, Vegetarian, and Original options!",
      image: "../images/build2.png",
      class: "build-reverse"
    },
    {
      title: "Variety to Satisfy Every Craving",
      content: "Discover 10+ new items every month (20+ in our #SuperSnacker boxes!). Each snack maximizes nutrition without sacrificing taste!",
      image: "../images/build3.png",
      class: "build-display"
    },
    {
      title: "Supports Your Fitness Goals",
      content: "Our snacks and superfoods help promote a healthy, fit lifestyle. Plus, you’ll get a FREE workout inside every box!",
      image: "../images/build4.png",
      class: "build-reverse"
    },
    {
      title: "Absolutely NO Junk Inside",
      content: "Items NEVER contain cheap fillers, artificial ingredients, HFCS, preservatives, or anything else sketchy that was created in a lab.",
      image: "../images/build5.png",
      class: "build-display"
    },
  ];

  const [visibleElements, setVisibleElements] = useState(5);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Check initial width
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (isMobile) {
      setVisibleElements(2);
    }
  }, [isMobile]);
  const handleViewMore = () => {
    if(visibleElements === 2){
      setVisibleElements(box.length);
    }else{
      setVisibleElements(2);
    }
   
  };

  return (
    <div className="build-color">
      
      <div className="container">
        <h3 className="build-blue">Meet CLEAN.FIT:</h3>
        <h1 className="main-title">Snacks That Fuel Your Superpowers. 💪</h1>
        <p className="pb-50">We see you and your super-human ability to get ALL THE THINGS done in one day. 🤯</p>
        {box.slice(0, isMobile ? visibleElements : visibleElements).map((item) => {
          return (
            <div className={item.class}>
              <div className="w-45">
                <h1>{item.title}</h1>
                <p>{item.content}</p>
              </div>
              <div className="w-45">
                <img src={item.image} alt="#" className="w-100" />
              </div>
            </div>
          );
        })}
       
        {isMobile && (
          <div className="pt-50" onClick={handleViewMore}>
            <p className="morebuild-btn">
              {visibleElements === 2 ? "  View More" : "  View Less"}
            
            </p>
          </div>
        )}
            <div className="pt-50">
          <Link className="blue-btn"      to={
                      window.location.pathname.includes("homepage")
                        ? "/homepage/Bonus-Box-flow"
                        : "/join-now"
                    }>
         Build Your Box
       </Link>
       </div>
      </div>
    </div>
  );
};

export default Build;
