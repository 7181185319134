import React from "react";
import { generateImage } from "../../tools/constants";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
const Members = (data) => {
  // console.log(data.data);

  return (
    <div className="container">
      <h1 className="w-50 text-left product-title">
        {/* This is what our members got in {data.data.month} */}
        This is what our #cleanfitfam got in the {data.data.month} box!
      </h1>

      {data
        ? data.data.boxDetails.map(function (item, i) {
            return (
              <div
                className={
                  i % 2 === 0 ? "d-flex  member-display" : "d-flex  row-inverse"
                }
                key={i}
              >
                <img
                  className="w-45 product-size"
                  src={generateImage(item.image.data.attributes.url)}
                  alt="#"
                ></img>

                <div className="w-45 blue-bck">
                  <h1>{item.heading}</h1>
                  <p>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {item.description}
                  </ReactMarkdown>
                  {/* <div
              dangerouslySetInnerHTML={{
                __html: item.description,
              }}
            ></div> */}
            </p>
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default Members;
