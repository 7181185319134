import React from "react";
import { Link } from "react-router-dom";
import { generateImage } from "../../tools/constants";
import { AnimationOnScroll } from "react-animation-on-scroll";
const Relevant = ({ id, photoUrl, slug, title, hashtag, data }) => {
  return (
    <AnimationOnScroll
      animateIn="animate__slower animate__fadeInUp "
      animateOnce
    >
      <Link to={"/singleBlog/" + slug} className="relevant-link  ">
        <div className="w-100 d-flex relevant-border">
          <div>
            <img src={generateImage(photoUrl)} alt="#" className="w-100"></img>
          </div>
          <div className=" relevant">
            <h1>{title.substring(40, title) + "..."}</h1>
            <span># {hashtag}</span>
            <span>{data}</span>
          </div>
        </div>
      </Link>
    </AnimationOnScroll>
  );
};

export default Relevant;
