import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BoxImage from "../Components/Layout/BoxImage";
import axios from "axios";
import Groupmobile from "../Components/Layout/Groupmobile";
import { generateImage } from "../tools/constants";
import Loader from "../Loader";
import Faqs from "../Components/Layout/Faqs";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Gift from "./Gift";
import ShowMore from "react-show-more-button";
import { Helmet } from 'react-helmet';
const PastBoxes = () => {
  const [toggle, setToggle] = useState(-1);
  const [more, setMore] = useState(0);
  const [pastBoxes, setPastBoxes] = useState({
    pastBoxes: [],
    isLoaded: false,
  });
  const [boxes, setBoxes] = useState({
    boxes: [],
    isLoaded: false,
  });

  const viewMore = () => {
    const moreBox = boxes.boxes.length - 12;

    setMore(moreBox);
  };
  const viewLess = () => {
    const moreBox = 0;

    setMore(moreBox);
  };

  const [question, setQuestion] = useState({
    pages: [],
    isLoaded: false,
  });
  // console.log(pastBoxes.pastBoxes.pastBoxBannerImg);
  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/past-box?populate=deep,10&pagination[start]=0&pagination[limit]=-1")

      .then((resPages) => {
        axios
          .get("https://cms.cleanfitbox.com/api/boxes?populate=deep,10&pagination[start]=0&pagination[limit]=-1")

          .then((resBoxes) => {
            setPastBoxes({
              pastBoxes: resPages.data.data.attributes,
              isLoaded: true,
            });
            setBoxes({
              boxes: resBoxes.data.data,
              isLoaded: true,
            });
          });
        axios
          .get("https://cms.cleanfitbox.com/api/faq?populate=deep,10&pagination[start]=0&pagination[limit]=-1")
          .then((res) => {
            setQuestion({
              pages: res.data.data.attributes,
              isLoaded: true,
            });
          });
      })
      .catch((err) => console.log(err));
      // document.title="Subscription box for healthy snacks that are ACTUALLY healthy.";
      // document.querySelector('meta[name="description"]').setAttribute('content', "See what’s inside CLEAN.FIT box! Delicious clean snacks, superfoods, and supplements delivered monthly. Vegan, Vegetarian, Gluten-free and Dairy-free options.");

  }, []);

  // console.log(pastBoxes.pastBoxes);
  // console.log("boxesboxesboxesboxes", boxes);

  if (
    pastBoxes.isLoaded === false ||
    boxes.isLoaded === false ||
    question.isLoaded === false
  )
    return <Loader />;
  else {
    return (
      <>
        {/* <div className="lightblue">
          <div className="container d-row ">
            <AnimationOnScroll
              animateIn="animate__slower animate__fadeInLeft  "
              // animateOut=""
              animateOnce
            >
              <h1 className="first-title">
                {pastBoxes.pastBoxes.pastBoxHeading}
              </h1>
            </AnimationOnScroll> */}

        {/* <div className="banner-img">
              <img
                src={generateImage(
                  pastBoxes.pastBoxes.pastBoxBannerImg.data.attributes.url
                )}
                alt="#"
              />
            </div> */}
        {/* </div>
        </div> */}
       <Helmet>
           <title>Subscription box for healthy snacks that are ACTUALLY healthy.</title>
           <meta name="description" content="See what’s inside CLEAN.FIT box! Delicious clean snacks, superfoods, and supplements delivered monthly. Vegan, Vegetarian, Gluten-free and Dairy-free options." />
         </Helmet>
        <AnimationOnScroll
          className="container "
          animateIn="animate__slower animate__fadeInUp  "
          // animateOut=""
          animateOnce
        >
          <div className="group-text">
            <h1>{pastBoxes.pastBoxes.groupBox.title}</h1>
            {/* <p>{pastBoxes.pastBoxes.groupBox.subTitle}</p> */}

            <p>
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: pastBoxes.pastBoxes.groupBox.description,
                }}
              ></div> */}
              {pastBoxes.pastBoxes.groupBox.description}
            </p>
          </div>
          <AnimationOnScroll
            animateIn="animate__slower animate__fadeI  "
            // animateOut=""
            animateOnce
          >
            {" "}
            <img
              className="w-100 display-group"
              src={generateImage(
                pastBoxes.pastBoxes.groupBox.groupImg.data.attributes.url
              )}
              alt="#"
            />
          </AnimationOnScroll>
          <Groupmobile page={pastBoxes.pastBoxes} />
        </AnimationOnScroll>
        <div className="container boxes">
          <AnimationOnScroll
            animateIn="animate__slower animate__fadeInUp  "
            // animateOut=""
            animateOnce
          >
            {" "}
            <h1>{pastBoxes.pastBoxes.monthHeading}</h1>
          </AnimationOnScroll>
          <AnimationOnScroll
            className="d-flex box-wrap"
            animateIn="animate__slower animate__fadeInUp  "
            // animateOut=""
            animateOnce
          >
            {console.log("mapped boxes", boxes.boxes)}
            {boxes.boxes
              .slice(Math.max(boxes.boxes.length - 12 - more, 0))
              .sort()
              .reverse()
              .map((e, i) => {
                return (
                  <BoxImage
                    id={e.id}
                    slug={e.attributes.slug}
                    month={e.attributes.month}
                    photoUrl={e.attributes.featuredImage.data.attributes.url}
                    key={i}
                  />
                );
              })}
          </AnimationOnScroll>
          {boxes.boxes.length === 12 ? (
            ""
          ) : Math.max(
              boxes.boxes.length - boxes.boxes.length + 1 - more,
              0
            ) ? (
            <button className="view-more-pastbox" onClick={() => viewMore()}>
              View More
            </button>
          ) : (
            <button className="view-more-pastbox" onClick={() => viewLess()}>
              View Less
            </button>
          )}
          <AnimationOnScroll
            animateIn="animate__slower animate__fadeInUp  "
            // animateOut=""
            animateOnce
          >
            <h1 className="size-title">
              {pastBoxes.pastBoxes.pickASize.heading}
            </h1>
          </AnimationOnScroll>
          <div className="d-flex size-boxes">
            <AnimationOnScroll
              animateIn="animate__slower animate__fadeInLeft  "
              // animateOut=""
              animateOnce
            >
              <Link   to={
            window.location.pathname.includes("homepage")
              ? "/homepage/Bonus-Box-flow"
              : `/join-now`
          }>
                <img
                  src={generateImage(
                    pastBoxes.pastBoxes.pickASize.imageOne.data.attributes.url
                  )}
                  alt="#"
                ></img>
              </Link>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__slower animate__fadeInRight  "
              // animateOut=""
              animateOnce
            >
              <Link to={
            window.location.pathname.includes("homepage")
              ? "/homepage/Bonus-Box-flow"
              : `/join-now`
          }>
                <img
                  src={generateImage(
                    pastBoxes.pastBoxes.pickASize.imageTwo.data.attributes.url
                  )}
                  alt="#"
                ></img>
              </Link>
            </AnimationOnScroll>
          </div>
        </div>

        <div className="container  " id="boxes-faq">
          <AnimationOnScroll
            animateIn="animate__slower animate__fadeInUp  "
            // animateOut=""
            animateOnce
          >
            <h1 className=" faq-header">{question.pages.faqHeader}</h1>
            <div className="faq-line"></div>
          </AnimationOnScroll>
          {question.pages.faqs.slice(0, 4).map((item, i) => {
            return (
              <Faqs
                index={i}
                isActive={toggle === i}
                setToggle={setToggle}
                question={item.question}
                answer={item.answer}
                key={i}
              />
            );
          })}
          <AnimationOnScroll
            className="view-more pt-50"
            animateIn="animate__slower animate__fadeInUp  "
            // animateOut=""
            animateOnce
          >
            {" "}
            <Link to="/faq">View More</Link>
          </AnimationOnScroll>
        </div>
        {/* <div className="position-relative w-100">
          <div className="container ">
            <div className="gift-text">
              <h1 className="blue-title">
                {pastBoxes.pastBoxes.purchaseHeading}
              </h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: pastBoxes.pastBoxes.purchaseDescription,
                }}
              ></div>
            </div>
          </div>
          <div className="bck-gift">
            <img
              src={generateImage(
                pastBoxes.pastBoxes.purchaseImg.data.attributes.url
              )}
              alt="#"
            />
          </div>
        </div> */}

        <Gift />
      </>
    );
  }
};

export default PastBoxes;
