import React from "react";
import CustomForm from "../tools/CustomForm";
import MailchimpFormContainer from "../tools/MailchimpFormContainer";

function Mailchimp(props) {
  return (
    <>
      <CustomForm />
      {/* <MailchimpFormContainer /> */}
    </>
  );
}
export default Mailchimp;
