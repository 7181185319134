import React from "react";
import { Link } from "react-router-dom";
import { generateImage } from "../../tools/constants";
import { AnimationOnScroll } from "react-animation-on-scroll";
const ArticleBox = ({
  id,
  slug,
  photoUrl,
  title,
  hashtag,
  recive,
  read,
  data,
}) => {
  return (
    <AnimationOnScroll
      className="blog-box"
      animateIn="animate__slower animate__fadeInUp"
      animateOnce
      key={id}
    >
      <Link to={"/singleBlog/" + slug}>
        <img src={generateImage(photoUrl)} alt="#"></img>
      </Link>
      <div className="blog">
        <div className="time">
          <p>{read}</p>
          <p>{data}</p>
        </div>
        <h1>{title.substring(50, title) + "..."}</h1>

        <span># {hashtag}</span>
        {/* <span>{recive}</span> */}
      </div>
    </AnimationOnScroll>
  );
};

export default ArticleBox;
