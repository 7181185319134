import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import SingleTab from "../Tabs/SingleTab";
import StepOne from "../Steps/stepOne";
import StepTwo from "../Steps/stepTwo";
import StepThree from "../Steps/stepThree";
import StepFour from "../Steps/stepFour";
import StepFive from "../Steps/stepFive";
import StepSix from "../Steps/stepSix";
import CustomFormSignup from "../../tools/Signupemail";
import StepSeven from "../Steps/stepSeven";
import StepEight from "../Steps/stepEight";
import { GoCheck } from "react-icons/go";
import axios from "axios";
import Loader from "../../Loader";
import Followers from "./Followers";
import { Link, useLocation } from "react-router-dom";
import Customize from "./customize";

const Steps = (props) => {
  const [steps, setSteps] = useState({
    steps: [],
    isLoaded: false,
  });
  const [page, setPage] = useState({
    pages: [],
    isLoaded: false,
  });
console.log(props)
  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/sign-up?populate=deep,10")
      .then((resStep) => {
        setSteps({
          steps: resStep.data.data.attributes,
          isLoaded: true,
        });
      });
    axios
      .get("https://cms.cleanfitbox.com/api/homepage?populate=deep,10")
      .then((res) => {
        setPage({
          pages: res.data.data.attributes,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
      document.title="Subscription box for healthy snacks that are ACTUALLY healthy."
      document.getElementsByTagName("META")[2].content="Delicious clean snacks, superfoods, and supplements delivered monthly. Organic and all-natural. Vegan, Vegetarian, Gluten-free and Dairy-free options.      "
  }, []);

  // console.log(steps.pages);
  const [step, setStep] = useState(0);

  const [data, setData] = useState({
    firstName: "",
    picked: "",
    pickedBox: [],
    email: "",
    pickPreference: [],
    pickPreferenceTitle: [],
    recommendation: "",
    pickBoxShop: "",
    // superSnack: "",
  });

  // const [errors, setErrors] = useState({});

  // const makeRequest = (formData) => {
  //   console.log("form submitted", formData);
  // };

  const handleNextStep = (name, value, final = false) => {
    setData((prev) => ({ ...prev, [name]: value }));

    // if (final) {
    //   // makeRequest(newData);
    //   return;
    // }
    
    setStep((prev) => prev + 1);


  };
  const handlePushData = (name, value, final = false) => {
    setData((prev) => ({ ...prev, [name]: value }));

    // if (final) {
    //   // makeRequest(newData);
    //   return;
    // }
    



  };

  const handlePickStep = (name, value, final = false) => {
    setData((prev) => ({ ...prev, [name]: value }));

    // if (final) {
    //   // makeRequest(newData);
    //   return;
    // }
    setStep((prev) => prev);
  };

  const handlePrevStep = () => {
    // setData((prev) => ({ ...prev, [name]: value }));
    // setStep((prev) => prev - 1);
    setStep(step - 1);
  };

  useEffect(() => {
    // console.log(data);
  }, [data]);

  const checkNextStepAvailability = (stepNumber) => {
    switch (stepNumber) {
      case 1:
        if (data.firstName.length > 1) return true;
        return false;
      // case 2:
      //   if (data.firstName.length > 2 && data.picked.length > 0) return true;
      //   return false;
      case 2:
        if (
          data.firstName.length > 1 &&
          data.picked.length > 0 &&
          data.pickedBox.length > 0
        )
          return true;
        return false;
      case 3:
        if (
          data.firstName.length > 1 &&
          data.picked.length > 0 &&
          data.pickedBox.length > 0 &&
          data.email.length > 0
        )
          return true;
        return false;
      case 4:
        if (
          data.firstName.length > 1 &&
          data.picked.length > 0 &&
          data.pickedBox.length > 0 &&
          data.email.length > 0 &&
          data.pickPreference.length > 0
        )
          return true;
        return false;
      case 5:
        if (
          data.firstName.length > 1 &&
          data.picked.length > 0 &&
          data.pickedBox.length > 0 &&
          data.email.length > 0 &&
          data.pickPreference.length > 0 &&
          data.recommendation.length > 0
        )
          return true;
        return false;
      case 6:
        if (
          data.firstName.length > 1 &&
          data.picked.length > 0 &&
          data.pickedBox.length > 0 &&
          data.email.length > 0 &&
          data.pickPreference.length > 0 &&
          data.recommendation.length > 0 &&
          data.pickBoxShop.length > 0
        )
          return true;
        return false;
      // case 8:
      //   if (
      //     data.firstName.length > 1 &&
      //     data.picked.length > 0 &&
      //     data.pickedBox.length > 2 &&
      //     data.email.length > 2 &&
      //     data.pickPreference.length > 2 &&
      //     data.recommendation.length > 2 &&
      //     data.pickBoxShop.length > 2 &&
      //     data.superSnack.length > 2
      //   )
      //     return true;
      //   return false;

      default:
        return false;
    }
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get('offer');
  // useEffect(() => {
    console.log(paramValue);
  // }, [location.search])

  const stepsOption = [
    <StepOne
      next={handleNextStep}
      data={data}
      page={steps.steps}
      icon={page.pages}
    />,
    // <StepTwo
    //   next={handleNextStep}
    //   data={data}
    //   page={steps.steps}
    //   prev={handlePrevStep}
    // />,
    <StepThree
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      page={steps.steps}
    />,
    // <StepFour
    //   next={handleNextStep}
    //   prev={handlePrevStep}
    //   data={data}
    //   page={steps.steps}
    // />,
    <CustomFormSignup
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      page={steps.steps}
    
    />,

    
  
    <StepFive
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      page={steps.steps}
      handlePickStep={handlePickStep}
   
    />,


    <StepSix
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      page={steps.steps}
      paramValue={paramValue}  
      dataPush={handlePushData}
    />,
    <StepSeven
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      page={steps.steps}
    />,
    // <StepEight
    //   next={handleNextStep}
    //   prev={handlePrevStep}
    //   data={data}
    //   page={steps.steps}
    // />,
  ];

  if (steps.isLoaded === false || page.isLoaded === false) return <Loader />;
  else {
    // console.log("dataaaa", data.email.length);
    return (
      <div className="steps">
        <div className="steps-display">
          <Link to="/">
            <div className="nav-logo">
              {" "}
              <img src="../images/logo.svg"></img>
            </div>
          </Link>
        </div>
        <ul>
          <li
            onClick={() => setStep(0)}
            className={`${step === 0 ? "active_step" : "newclass"}`}
          >
            {step > 0 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepOne} </p>
            )}
          </li>
          <div className="progress-line"></div>
          <li
            onClick={() => (checkNextStepAvailability(1) ? setStep(1) : null)}
            className={`${
              step === 1 ? "active_step" : step > 1 ? "newclass" : ""
            }`}
          >
            {step > 1 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepTwo} </p>
            )}
          </li>
          <div className="progress-line"></div>
          <li
            onClick={() => (checkNextStepAvailability(2) ? setStep(2) : null)}
            className={`${
              step === 2 ? "active_step" : step > 2 ? "newclass" : ""
            }`}
          >
            {step > 2 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepThree} </p>
            )}
          </li>
          <div className="progress-line"></div>
          <li
            onClick={() => (checkNextStepAvailability(3) ? setStep(3) : null)}
            className={`${
              step === 3 ? "active_step" : step > 3 ? "newclass" : ""
            }`}
          >
            {step > 3 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepFour} </p>
            )}
          </li>
          <div className="progress-line"></div>
          <li
            onClick={() => (checkNextStepAvailability(4) ? setStep(4) : null)}
            className={`${
              step === 4 ? "active_step" : step > 4 ? "newclass" : ""
            }`}
          >
            {step > 4 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepFive} </p>
            )}
          </li>
          <div className="progress-line"></div>
          <li
            onClick={() => (checkNextStepAvailability(5) ? setStep(5) : null)}
            className={`${
              step === 5 ? "active_step" : step > 5 ? "newclass" : ""
            }`}
          >
            {step > 5 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepSix} </p>
            )}
          </li>
          {/* <div className="progress-line"></div>
          <li
            onClick={() => (checkNextStepAvailability(6) ? setStep(6) : null)}
            className={`${
              step === 6 ? "active_step" : step > 6 ? "newclass" : ""
            }`}
          >
            {step > 6 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepSeven} </p>
            )}
          </li> */}
          {/* <div className="progress-line"></div>
          <li
            onClick={() => (checkNextStepAvailability(7) ? setStep(7) : null)}
            className={`${
              step === 7 ? "active_step" : step > 7 ? "newclass" : ""
            }`}
          >
            {step > 7 ? (
              <GoCheck className="tick"></GoCheck>
            ) : (
              <p className="step-circle"> {steps.steps.stepEight} </p>
            )}
          </li> */}
        </ul>

        <div>{stepsOption[step]}</div>
        {/* <div className="trustpilot">
          <img src="../images/image.png" alt="#" />
        </div> */}
        <div className=" pt-50">
          <Followers page={page.pages} />
        </div>
      </div>
    );
  }
};

export default Steps;
