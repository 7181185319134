import axios from "axios";
import React, { useEffect, useState } from "react";
import { generateImage } from "../tools/constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../Loader";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Helmet } from 'react-helmet';
const Business = () => {
  const [active, setActive] = useState(-1);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      companyName: "",
      message: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required(""),

      email: Yup.string().email("Invalid email address").required(""),
      companyName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required(""),
      message: Yup.string()
        .max(200, "Must be 200 characters or less")
        .required(""),
    }),
    onSubmit: (values) => {
      // console.log(values);
    },
  });

  const [business, setBusiness] = useState({
    business: [],
    isLoaded: false,
  });

  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/business?populate=deep,10")
      .then((res) => {
        setBusiness({
          business: res.data.data.attributes,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
      // document.title=" Healthy snacks promote wellness, especially for remote workers!";
      // document.querySelector('meta[name="description"]').setAttribute('content', "Healthy snack boxes make a great employee perk! Bulk discounts and flexible terms available. Vegan, Vegetarian, Gluten-free and Dairy-free dietary options.");
 
  }, []);

  // console.log(business.pages);

  if (business.isLoaded === false) return <Loader />;
  else {
    return (
      <>
        <Helmet>
           <title>Subscription box for healthy snacks that are ACTUALLY healthy.</title>
           <meta name="description" content="Healthy snack boxes make a great employee perk! Bulk discounts and flexible terms available. Vegan, Vegetarian, Gluten-free and Dairy-free dietary options." />
         </Helmet>
        <div className="container business">
          <AnimationOnScroll
            animateIn="animate__slower animate__fadeIn "
            // animateOut=""
            animateOnce
          >
            <h1>{business.business.businessHeading}</h1>
          </AnimationOnScroll>
          <div className="business-img">
            <AnimationOnScroll
              animateIn="animate__slower animate__fadeIn "
              // animateOut=""
              animateOnce
            >
              <img
                src={generateImage(
                  business.business.businessBanner.data.attributes.url
                )}
                alt="#"
              ></img>
            </AnimationOnScroll>
            <p className="pd-20">{business.business.snackHeader}</p>
            <div className="d-flex ">
              <AnimationOnScroll
                animateIn="animate__slower animate__fadeIn "
                // animateOut=""
                animateOnce
              >
                <p>
                  But healthy snacks also have superpowers: they can increase
                  productivity & focus, 🧠 and give a much-needed energy boost
                  💪 to beat that afternoon slump. Further, snack boxes boost
                  employee engagement and promote health and wellness! Did you
                  know: an HBR study found that employers who invest in wellness
                  initiatives saw a nearly 3-to-1 return on their money! 💰
                  CLEAN.FIT box is the subscription box for healthy snacks that
                  are actually healthy. And they make a great perk for
                  employees!
                </p>
              </AnimationOnScroll>
            </div>
          </div>
        </div>

        <div className="business-gray  ptb-100">
          <div className="employee">
            <AnimationOnScroll
              animateIn="animate__slower animate__fadeInUp "
              // animateOut=""
              animateOnce
            >
              <h1>{business.business.boxHeader}</h1>
            </AnimationOnScroll>

            <AnimationOnScroll
              className="business-box   business-img"
              animateIn="animate__slower animate__fadeInUp "
              // animateOut=""
              animateOnce
            >
              {business.business.boxes.map((item, i) => {
                return (
                  <div key={i}>
                    <h3 className="titleBusiness-hover">{item.title}</h3>
                    <p className="hover-bussines">{item.description}</p>
                  </div>
                );
              })}
            </AnimationOnScroll>

            <AnimationOnScroll
              animateIn="animate__slower animate__fadeInUp "
              // animateOut=""
              animateOnce
            >
              <h1 className="employee-heading">
                {business.business.employeeHeading}
              </h1>
              <div className="d-flex big-number">
                <h1>{business.business.bigNumber}</h1>
                <p>{business.business.employeeDescription}</p>
              </div>
            </AnimationOnScroll>
          </div>
        </div>

        <div className="container d-flex ptb-100">
          <AnimationOnScroll
            className="learn-businesss"
            animateIn="animate__slower animate__fadeInLeft "
            // animateOut=""
            animateOnce
          >
            <h1>{business.business.contactHeading}</h1>
            <p>{business.business.contactSubHeading}</p>
            {/* <form className="contact-us" onSubmit={formik.handleSubmit}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="firstName"
                placeholder="Enter your Full Name"
                id="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              ></input>
              {formik.touched.firstName && formik.errors.firstName ? (
                <p className="error-msg name-error">
                  {formik.errors.firstName}
                </p>
              ) : null}
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your Email Address"
                id="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              ></input>
              {formik.touched.email && formik.errors.email ? (
                <p className="error-msg businessemail-error">
                  {formik.errors.email}
                </p>
              ) : null}
              <label htmlFor="company">Company Name</label>
              <input
                type="text"
                name="companyName"
                placeholder="Enter your Company Name"
                id="company"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
              ></input>
              {formik.touched.companyName && formik.errors.companyName ? (
                <p className="error-msg company-error">
                  {formik.errors.companyName}
                </p>
              ) : null}
              <label htmlFor="message">Message Box</label>
              <textarea
                placeholder="Hello"
                id="message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
              ></textarea>
              {formik.touched.message && formik.errors.message ? (
                <p className="error-msg textarea-error">
                  {formik.errors.message}
                </p>
              ) : null}
              <input type="submit" className="contact-submit"></input>
            </form> */}
            <iframe
              id="contact"
              title="contact"
              name="contact"
              className="contact-iframe"
              src="https://us14.list-manage.com/contact-form?u=1e2f83d82088156626640bfc3&form_id=e45f205abf1df9acc3992d8303d416ac"
            ></iframe>
            {/* {page.pages.Question.map((item, i) => (
            <>
              <p
                onClick={() => (active === i ? setActive(i - 4) : setActive(i))}
                className={`bussines-active ${
                  active === i ? "active_business" : ""
                }`}
              >
                {item.question}
              </p>

              {active === i && <span className="span-show">{item.answer}</span>}
            </>
          ))} */}
          </AnimationOnScroll>
          <AnimationOnScroll
            className="learn-business-img"
            animateIn="animate__slower animate__fadeInRight"
            // animateOut=""
            animateOnce
          >
            <img
              src={generateImage(
                business.business.contactImage.data.attributes.url
              )}
              alt="#"
            ></img>
          </AnimationOnScroll>
        </div>
      </>
    );
  }
};

export default Business;
