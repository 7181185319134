import React, { useState, useEffect } from "react";
// import "./mcFormStyles.scss";
import MailchimpSubscribe from "react-mailchimp-subscribe";
// import ReactPixel from "react-facebook-pixel";

// import { useGHStContext } from "../../../utils/ContextProvider";
import InputField from "./InputField";
import { FiMail } from "react-icons/fi";
import { Link } from "react-router-dom";
const CustomFormSignup = ({ props, status, message, onValidated, send ,paramValue}) => {


  const [messageError, setMessageError] = useState("");
  const [email, setEmail] = useState(props.data.email);

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }
  var simulateClick = function (elem) {
    // Create our event (with options)
    var evt = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    // If cancelled, don't dispatch our event
    var canceled = !elem.dispatchEvent(evt);
    console.log(canceled);
  };
  const handleSubmit = (e) => {
    // e.preventDefault();

    // email &&
    //   email.indexOf("@") > -1 &&
    //   onValidated({
    //     EMAIL: email,
    //     tags: "6994640",
    //   });
    // console.log("bosi");
    if (validation(email)) {
    timeout(1000).then((e) => {
      props.next("email", email);
    });
  }
  };

  const firstEmailChange = (e) => {
    setEmail(e.target.value);
    validation(e.target.value);
  };


console.log("log",email)

  const validation = (val) => {
    var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (val.length === 0) {
      setMessageError("Please enter a email address");
      return false;
    } else if (!regex.test(val)) {
      setMessageError("Please enter a valid email address");
      return false;
    } else {
      setMessageError("");
      return true;
    }
  };


  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const clearFields = () => {
    // setFirstName("");
    setEmail("");
  };

  return (
    <>
      <div className=" container">
        <h3 className="step-header">{props.page.stepFourHeading}</h3>
        <form
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate step-form"
          noValidate
          onSubmit={handleSubmit}
        >
          <h3 className="mc__title">
            {status === "success" ? "Success! 😊" : ""}
          </h3>

          {status === "sending" && (
            <div className="mc__alert mc__alert--sending">sending...</div>
          )}
          {status === "error" && (
            <div
              className="mc__alert mc__alert--error"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === "success" && (
            <div
              className="mc__alert mc__alert--success"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}

          <div className="mc__field-container">
            <div className="">
              <input
                onChange={firstEmailChange}
                type="email"
                name="EMAIL"
                className="required email step-one"
                id="mce-EMAIL"
                value={send}
                placeholder="your@email.com"
                isRequired
              />
              {messageError && <p className="error-msg">{messageError}</p> }
              <div hidden="true">
                <input type="hidden" name="tags" value="6994640" />
              </div>

              <div className="step-four">
                {" "}
                <input
                  type="checkbox"
                  id="scales"
                  name="scales"
                  checked
                  value={0}
                  required
                ></input>
                <label htmlFor="scales">
                  By clicking you agree to our <Link to="/policy"> Terms</Link>{" "}
                  and
                  <Link to="/policy"> Privacy Policy. </Link>
                </label>
              </div>
            </div>
            <div className="mtb-20">
              <input
                label="subscribe"
                type="submit"
                className="formEmailButton step-button"
                formValues={[email]}
                formTarget="dummyframe"
                id="mc-embedded-subscribed"
                name="subscribe"
                value="Next"
                onClick={() => {
                  // ReactPixel.track("Lead");
                  // var someLink1 = document.getElementById(
                  //   "mc-embedded-subscribe"
                  // );

                  // // console.log("linku", someLink1);
                  // simulateClick(someLink1);
                  document.getElementById("sub-form").click();
                  // console.log("linku", someLink1);
                }}
              />
            </div>
            <div className="go-back" onClick={props.prev}>
              <p>Go Back</p>
            </div>
          </div>
        </form>
        <iframe
          id="test2"
          name="dummyframe"
          style={{ display: "none", width: "0", height: "0" }}
        ></iframe>
        <form
          action="https://cleanfitbox.us14.list-manage.com/subscribe/post?u=1e2f83d82088156626640bfc3&amp;id=ede0e991d8&amp;f_id=00a381e1f0"
          id="mailchimpforma"
          name="mailchimpforma"
          className="validate step-form"
          target="_blank"
          noValidate
          style={{ display: "none" }}
          method="post"
        >
          <div className="mc__field-container">
            <div className="">
              <input
                onChange={firstEmailChange}
                type="email"
                name="EMAIL"
                className="required email step-one"
                id="mce-EMAIL"
                value={email}
                placeholder="your@email.com"
                isRequired
              />
              <div hidden="true">
                <input type="hidden" name="tags" value="6994640" />
              </div>
            </div>
            <div className="mtb-20">
              <input
                label="subscribe"
                type="submit"
                className="formEmailButton step-button"
                formValues={[email]}
                formTarget="dummyframe2"
                id="sub-form"
                name="subscribe"
                // value="next"
              />
            </div>
          </div>
        </form>
      </div>
      <iframe
        id="test3"
        name="dummyframe2"
        style={{ display: "none", width: "0", height: "0" }}
      ></iframe>
    </>
  );
};

const MailchimpForm = (props) => {
  const url = `https://cleanfitbox.us14.list-manage.com/subscribe/post?u=1e2f83d82088156626640bfc3&amp;id=ede0e991d8&amp;f_id=00a381e1f0`;

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomFormSignup
            props={props}
            status={status}
            message={message}
            onValidated={(formData) => console.log(formData)}
          />
        )}
      />
    </div>
  );
};

export default MailchimpForm;
