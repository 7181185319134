import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import { generateImage } from "../tools/constants";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Helmet } from 'react-helmet';
const Coaching = () => {
  const [coaching, setCoaching] = useState({
    coaching: [],
    isLoaded: false,
  });

  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/coaching?populate=deep,10")
      .then((res) => {
        setCoaching({
          coaching: res.data.data.attributes,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
      // document.title=" Wellness coaching to help you live your happiest, healthiest life";
      // document.querySelector('meta[name="description"]').setAttribute('content', "So busy that you’re struggling to find time to workout, cook healthy meals, and make time for self-care? Let us help you with CLEAN.FIT wellness coaching!");
   
  }, []);

  // console.log(coaching.coaching);
  if (coaching.isLoaded === false) return <Loader />;
  else {
    return (
     <>
     <Helmet>
           <title>Subscription box for healthy snacks that are ACTUALLY healthy.</title>
           <meta name="description" content="So busy that you’re struggling to find time to workout, cook healthy meals, and make time for self-care? Let us help you with CLEAN.FIT wellness coaching!" />
         </Helmet>
      <div className="container">
        <div className="d-flex coaching w-100">
          <AnimationOnScroll
            className="coaching-img"
            animateIn="animate__slower animate__fadeInLeft  "
            // animateOut=""
            animateOnce
          >
            <img
              src={generateImage(
                coaching.coaching.coachingBanner.data.attributes.url
              )}
              alt="#"
            ></img>
          </AnimationOnScroll>
          <AnimationOnScroll
            className="w-50"
            animateIn="animate__slower animate__fadeInRight  "
            // animateOut=""
            animateOnce
          >
            <h1>{coaching.coaching.coachHeading}</h1>
            <h1>{coaching.coaching.coachSubHeading}</h1>
            <p>
              <div
                className="coaching-text"
                dangerouslySetInnerHTML={{
                  __html: coaching.coaching.cochingText,
                }}
              ></div>
            </p>
            <p className="coach-price">${coaching.coaching.price}</p>
            <Link to="/join-now" style={coaching.coaching.cart.style}>
              {coaching.coaching.cart.buttonText}
            </Link>
          </AnimationOnScroll>
        </div>

        <div className="d-flex session">
          <AnimationOnScroll
            className="d-flex "
            animateIn="animate__slower animate__fadeInUp  "
            // animateOut=""
            animateOnce
          >
            {coaching.coaching.Box.map((item) => {
              return (
                <div className="session-display">
                  <img
                    src={generateImage(item.image.data.attributes.url)}
                    alt="#"
                  ></img>
                  <h1>{item.title}</h1>
                  <p>{item.description}</p>
                </div>
              );
            })}
          </AnimationOnScroll>

          <AnimationOnScroll
            className="program"
            animateIn="animate__slower animate__fadeInUp  "
            // animateOut=""
            animateOnce
          >
            <h1>{coaching.coaching.trainingTitle}</h1>
            <p>{coaching.coaching.trainingDescription}</p>
          </AnimationOnScroll>
        </div>
      </div>
     </>
    );
  }
};

export default Coaching;
