import React, { useState, useEffect } from "react";
import { number } from "yup";
import { generateImage } from "../../tools/constants";
// import { Form, Field, Formik, ErrorMessage } from "formik";
// import * as Yup from "yup";
import gtag from 'ga-gtag';
const StepOneGift = (props) => {
  // console.log(props.icon);
  // console.log(props);
  const [message, setMessage] = useState("");
  const [firstName, setFirstName] = useState(props.data.firstName);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validation(firstName)){ props.next("firstName", firstName);
  
    gtag('event', "stepOneGift", {
      event_category: "signupFlow",
      event_label:firstName,
    });
  }
  };

  const firstNameChange = (e) => {
    validation(e.target.value);
    setFirstName(e.target.value);
  };

  const validation = (val) => {
    var regex = /^[a-zA-Z- ]{1,30}$/;

    if (val.length === 0) {
      setMessage("");
      return false;
    } else if (!regex.test(val)) {
      setMessage("Enter a valid name!");
      return false;
    } else {
      setMessage("");
      return true;
    }
  };
  // console.log("aaaaaaaaaaaaaa", props.data.firstName + firstName);
  return (
    <div className=" container">
      {/* <p className="step-heading">{props.page.stepHeading}</p> */}
      <h3 className="step-header">  Hey there! 👋 So glad you want to give the gift of CLEAN.FIT!</h3>

      <form className="step-form" onSubmit={handleSubmit}>
        <input
          className="step-one"
          type="text"
          placeholder="What’s your name? 😊"
          id="firstName"
          name="firstName"
          value={firstName}
          onChange={firstNameChange}
        ></input>
        {message ? <p className="error-msg stepone-error">{message}</p> : null}
        {/* <p className="error-msg">{message}</p> */}
        <div className="mtb-20">
          <input className="step-button" type="submit" value="Next"></input>
        </div>
      </form>

      {/* <div className="step-benefit">
        <h1 className="main-title">{props.icon.benefitsHeading}</h1>
        <p>{props.icon.benefitsSubHeading}</p>
        <div className="w-20 benefit-data">
          {props.icon.benefitIcon.map(function (item, i) {
            return (
              <div key={i}>
                <img
                  src={generateImage(item.image.data.attributes.url)}
                  alt="benefit-group"
                />
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
};

export default StepOneGift;
