import axios from "axios";
import React, { useEffect, useState } from "react";
import ArticleBox from "../Layout/ArticleBox";
import moment from "moment";
const AllArticles = () => {
  const [more, setMore] = useState(0);
  const [blog, setBlog] = useState({
    blogs: [],
    isLoaded: false,
  });

  const viewMore = () => {
    const moreBox = blog.blogs.length - 6;

    setMore(moreBox);
  };
  const viewLess = () => {
    const moreBox = 0;

    setMore(moreBox);
  };

  useEffect(() => {
    axios
      .get(
        "https://cms.cleanfitbox.com/api/blogs?populate=deep,10&pagination[start]=0&pagination[limit]=-1"
      )
      .then((resPages) => {
        setBlog({
          blogs: resPages.data.data,
          isLoaded: true,
        });
      })

      .catch((err) => console.log(err));
  }, []);
  // console.log("blogs",blog.blogs)
  return blog.isLoaded ? (
    <>
      {/* <h1 className="title">All Article</h1> */}
      <div className="blog-display w-100 ">
        {blog.blogs
          .slice(Math.max(blog.blogs.length - 6 - more, 0))
          .reverse()
          .map((e, i) => {
            const date = e.attributes.createdAt;
            var now = moment
              .utc(date, "YYYY-MM-DDTHH:mm:ssZ")
              .format("YYYY-MM-DD");
            return (
              <ArticleBox
                id={e.id}
                photoUrl={e.attributes.blogBox.image.data.attributes.url}
                key={i}
                title={e.attributes.blogBox.title}
                slug={e.attributes.slug}
                hashtag={e.attributes.Category}
                recive={e.attributes.blogBox.recipe}
                read={e.attributes.blogBox.read}
                data={now}
              />
            );
          })}
      </div>
      {blog.blogs.length === 6 ? (
        ""
      ) : Math.max(blog.blogs.length - blog.blogs.length + 1 - more, 0) ? (
        <button className="view-more-pastbox mb-all" onClick={() => viewMore()}>
          View More
        </button>
      ) : (
        <button className="view-more-pastbox mb-all" onClick={() => viewLess()}>
          View Less
        </button>
      )}
    </>
  ) : (
    <></>
  );
};

export default AllArticles;
