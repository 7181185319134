import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Loader from "../../Loader";
import AllArticles from "../articles/AllArticles";
import Healthy from "../articles/Healthy";
import Lifestyle from "../articles/Lifestyle";
import Recipe from "../articles/Recipe";
import Workout from "../articles/Workout";
import { AnimationOnScroll } from "react-animation-on-scroll";
const Articles = () => {
  const [article, setArticle] = useState(0);

  const [articleClick, setArticleClick] = useState({
    AllArticles: false,
    Workout: false,
    Recipe: false,
    Healthy: false,
    Lifestyle: false,
  });
  const articleOption = [
    <AllArticles />,
    <Workout />,
    <Recipe />,
    <Healthy />,
    <Lifestyle />,
  ];

  const [singleBlog, setSingleBlog] = useState({
    singleBlog: [],
    isLoaded: false,
  });
  // const [blog, setBlog] = useState({
  //   blogs: [],
  //   isLoaded: false,
  // });

  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/single-blog?populate=deep,10")

      // .then((resPages) => {
      //   axios
      //     .get(
      //       "https://cms.cleanfitbox.com/api/blogs?populate=deep,10"
      //     )

      .then((resBoxes) => {
        setSingleBlog({
          singleBlog: resBoxes.data.data.attributes,
          isLoaded: true,
        });
        // setBlog({
        //   blogs: resBoxes.data.data,
        //   isLoaded: true,
        // });
      })
      // })
      .catch((err) => console.log(err));
  }, []);

  return singleBlog.isLoaded ? (
    <AnimationOnScroll
      className="articles"
      animateIn="animate__slower animate__fadeInUp"
      animateOnce
    >
      <h1 className="article-title">All Articles </h1>
      <ul className="pb-50">
        {singleBlog.singleBlog.tabs.map((item, i) => (
          <Link
            to={`/articles/${item.heading.toLowerCase()}`}
            className="articles-tab-link"
            key={i}
          >
            <li>
              <p>{item.heading}</p>
            </li>
          </Link>
        ))}
      </ul>
      {/* <div className="all-articles-height">{articleOption[article]}</div> */}
      {/* <AnimationOnScroll
        className="view-more"
        animateIn="animate__slower animate__fadeInUp"
        animateOnce
      >
        <Link to="/summer">View More</Link>
      </AnimationOnScroll> */}
    </AnimationOnScroll>
  ) : (
    <></>
  );
};

export default Articles;
