import React, { useEffect, useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Loader from "../Loader";
import Subscribe from "../Components/Layout/Subscribe";
import { generateImage } from "../tools/constants";

import { BsArrowRightShort } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { Helmet } from 'react-helmet';

const Contact = () => {
  const formik = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      PhoneNumber: "",
      message: "",
      subject: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required(""),

      email: Yup.string().email("Invalid email address").required(""),
      PhoneNumber: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required(""),
      subject: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required(""),
      message: Yup.string()
        .max(200, "Must be 200 characters or less")
        .required(""),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const [page, setPage] = useState({
    pages: [],
    isLoaded: false,
  });

  const [contact, setContact] = useState({
    contacts: [],
    isLoaded: false,
  });

  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/contact?populate=deep,10")
      .then((resContact) => {
        setContact({
          contacts: resContact.data.data.attributes,
          isLoaded: true,
        });
      });
    axios
      .get("https://cms.cleanfitbox.com/api/homepage?populate=deep,10")
      .then((res) => {
        setPage({
          pages: res.data.data.attributes,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  // console.log(contact.contacts);
  // console.log(page.pages);
  if (page.isLoaded === false || contact.isLoaded === false) return <Loader />;
  else {
    return (
      <>
      <Helmet>
           <title>Subscription box for healthy snacks that are ACTUALLY healthy.</title>
           <meta name="description" content="Delicious, clean snacks, superfoods, and supplements delivered monthly. Organic and all-natural. Vegan, Vegetarian, Gluten-free and Dairy-free options." />
         </Helmet>
        <div className="contact-banner w-100">
          <img
            src={generateImage(contact.contacts.banner.data.attributes.url)}
            alt="#"
            className="w-100  causes-none-desktop"
          ></img>
          <img
            src={generateImage(
              contact.contacts.mobileBanner.data.attributes.url
            )}
            alt="#"
            className="w-100  causes-none"
          ></img>
          <div className="container p">
            <div className="ptb-100">
              <h1>{contact.contacts.title}</h1>
              <p>{contact.contacts.description}</p>
            </div>
            <div className="contact-form pb-50">
              <div className="d-flex ">
                <AnimationOnScroll
                  className="w-50 contact-map"
                  animateIn="animate__slower animate__fadeInLeft "
                  // animateOut=""
                  animateOnce
                >
                  <div>
                    <img
                      src={generateImage(
                        contact.contacts.founderImg.data.attributes.url
                      )}
                      alt="#"
                      className="w-100"
                    ></img>
                  </div>
                  {/* <div>{contact.contacts.founder}</div> */}
                  <div
                    className="coaching-text"
                    dangerouslySetInnerHTML={{
                      __html: contact.contacts.founder,
                    }}
                  ></div>
                  <div className="form-icon">
                    <p>
                      {" "}
                      <MdOutlineEmail />{" "}
                    </p>{" "}
                    <p> hello@cleanfitbox.com</p>
                  </div>
                  <div className="form-icon">
                    {/* <p><MdOutlineEmail/></p><p>   info@cleanfitbox.com </p> */}
                    <a href="https://www.subbly.co/login">My Account </a>
                    <BsArrowRightShort />
                  </div>
                </AnimationOnScroll>

                <AnimationOnScroll
                  className="learn-business "
                  animateIn="animate__slower animate__fadeInRight "
                  // animateOut=""
                  animateOnce
                >
                  {/* <form className="contact-us" onSubmit={formik.handleSubmit}>
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Enter your Full Name"
                      id="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                    ></input>
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <p className="error-msg name-error">
                        {formik.errors.firstName}
                      </p>
                    ) : null}
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your Email Address"
                      id="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    ></input>
                    {formik.touched.email && formik.errors.email ? (
                      <p className="error-msg businessemail-error">
                        {formik.errors.email}
                      </p>
                    ) : null}
                    <label htmlFor="Phone">Phone Number</label>
                    <input
                      type="text"
                      name="PhoneNumber"
                      placeholder="Enter your Phone Number"
                      id="Phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.PhoneNumber}
                    ></input>
                    {formik.touched.PhoneNumber && formik.errors.PhoneNumber ? (
                      <p className="error-msg company-error">
                        {formik.errors.PhoneNumber}
                      </p>
                    ) : null}

                    <label htmlFor="subject">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      placeholder="Enter the Subject"
                      id="subject"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subject}
                    ></input>
                    {formik.touched.subject && formik.errors.subject ? (
                      <p className="error-msg subject-error">
                        {formik.errors.subject}
                      </p>
                    ) : null}

                    <label htmlFor="message">Message Box</label>
                    <textarea
                      placeholder="Hello"
                      id="message"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                    ></textarea>
                    {formik.touched.message && formik.errors.message ? (
                      <p className="error-msg textForm-error">
                        {formik.errors.message}
                      </p>
                    ) : null}
                
                    <input type="submit" formTarget="contact" className="contact-submit" value="submit"/>
                  </form> */}
                  <iframe
                    id="contact"
                    title="contact"
                    name="contact"
                    className="contact-iframe"
                    src="https://us14.list-manage.com/contact-form?u=1e2f83d82088156626640bfc3&form_id=e45f205abf1df9acc3992d8303d416ac"
                  ></iframe>
                </AnimationOnScroll>
              </div>

              {/* <Iframe url="https://us14.list-manage.com/contact-form?u=1e2f83d82088156626640bfc3&form_id=e45f205abf1df9acc3992d8303d416ac" 
          
          width="640px"
        height="100px"
        id="myiframe"
        className=""
        display="block"
        position="relative"
        overflow="hidden"
          /> */}
            </div>
          </div>
          {/* <form className="stack-1qp4V spacing6-nznRY" noValidate="" id="form-7li1w">
  <div className="stack-1qp4V spacing4-1xt6w">
    <h2 style={{ textAlign: "center" }}>Contact Us</h2>
  </div>
  <div className="FormFieldManagerField fieldWrapper-1vsl_">
    <div>
      <label
        aria-hidden="true"
        className="nonDefaultLabel-gxqPp"
        htmlFor="fields.80"
      >
        FIRST NAME
      </label>
      <div className="root-2RFjU">
        <div className="before-2EDUs">
          <label
            className="wink-label-default wink-visually-hidden"
            id="mc:2"
            htmlFor="fields.80"
          >
            FIRST NAME
          </label>
        </div>
        <div className="inputWrapper-TVkf7">
          <input
            type="text"
            id="fields.80"
            name="fields.80"
            destination="[object Object]"
            defaultValue=""
          />
        </div>
      </div>
    </div>
  </div>
  <div className="FormFieldManagerField fieldWrapper-1vsl_">
    <div>
      <label
        aria-hidden="true"
        className="nonDefaultLabel-gxqPp"
        htmlFor="fields.4"
      >
        Email *
      </label>
      <div className="root-2RFjU">
        <div className="before-2EDUs">
          <label
            className="wink-label-default wink-visually-hidden"
            id="mc:7"
            htmlFor="fields.4"
          >
            Email *
          </label>
        </div>
        <div className="inputWrapper-TVkf7">
          <input type="email" id="fields.4" name="fields.4" defaultValue="" />
        </div>
      </div>
    </div>
  </div>
  <div className="FormFieldManagerField fieldWrapper-1vsl_">
    <div>
      <label
        aria-hidden="true"
        className="nonDefaultLabel-gxqPp"
        htmlFor="fields.76"
      >
        PHONE
      </label>
      <div className="root-2RFjU">
        <div className="before-2EDUs">
          <label
            className="wink-label-default wink-visually-hidden"
            id="mc:12"
            htmlFor="fields.76"
          >
            PHONE
          </label>
        </div>
        <div className="inputWrapper-TVkf7">
          <input
            type="tel"
            id="fields.76"
            name="fields.76"
            destination="[object Object]"
            defaultValue=""
          />
        </div>
      </div>
    </div>
  </div>
  <div>
    <label
      aria-hidden="true"
      className="nonDefaultLabel-gxqPp"
      htmlFor="fields.8"
    >
      Subject *
    </label>
    <div className="root-2RFjU">
      <div className="before-2EDUs">
        <label
          className="wink-label-default wink-visually-hidden"
          id="mc:17"
          htmlFor="fields.8"
        >
          Subject *
        </label>
      </div>
      <div className="inputWrapper-TVkf7">
        <input type="text" id="fields.8" name="fields.8" defaultValue="" />
      </div>
    </div>
  </div>
  <div>
    <div className="root-iTNjU">
      <div className="before-2PdD3">
        <label className="wink-label-default" id="mc:22" htmlFor="mc:21">
          Message *
        </label>
      </div>
      <textarea
        id="fields.12"
        aria-describedby="mc:24"
        name="fields.12"
        rows={8}
        style={{ resize: "none" }}
        defaultValue={""}
      />
    </div>
  </div>
  <div className="root-1olwd">
    <input
      className="input-2v40B"
      id="mc:25"
      type="checkbox"
      indeterminate="false"
      aria-checked="false"
      name="subscribe"
    />
    <div className="text-vFMr3">
      <label id="mc:26" htmlFor="mc:25" className="">
        Subscribe to our newsletter
      </label>
    </div>
  </div>
  <div role="alert" className="formFeedback-2GdQc wink-visually-hidden" />
  <div className="cluster-3D5Qr">
    <div className="alignItemsCenter-1HCiJ justifyCenter-27O02 spacing4-1S_zR">
      <button
        className="root-sBgFt container-3-bH7 primary-33czz button-3mfLr"
        type="submit"
        style={{
          color: "rgb(255, 255, 255)",
          backgroundColor: "rgb(0, 124, 137)"
        }}
      >
        <span className="temporarySpan-2iF2p">Submit</span>
      </button>
    </div>
  </div>
</form> */}
        </div>

        {/* <Mailchimp
          action="https://cleanfitbox.us14.list-manage.com/subscribe/post?u=1e2f83d82088156626640bfc3&amp;id=8f8d67d46e&amp;f_id=00af81e1f0"
          fields={[
            {
              name: "FNAME",
              placeholder: "firstname",
              type: "text",
              required: true,
            },
            {
              name: "EMAIL",
              placeholder: "Email",
              type: "email",
              required: true,

              // required: true,
            },
            {
              name: "PHONE",
              placeholder: "PHONE",
              type: "text",
              required: true,

            },
            {
              name: "MMERGE5",
              placeholder: "Subject",
              type: "text",
              required: true,

            },
            {
              name: "MMERGE6",
              placeholder: "Message",
              type: "text",
            },
          ]}
        /> */}
        {/* <CustomForm /> */}

        <Subscribe page={page.pages} />
      </>
    );
  }
};

export default Contact;
