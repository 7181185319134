import React, { useState } from "react";
import ScrollToTop from "../../../src/ScrollToTop";
// import ReactPixel from "react-facebook-pixel";
import { Link } from "react-router-dom";
import gtag from 'ga-gtag';
import { useRef } from "react";
import { useEffect } from "react";
const StepSix = (props) => {
  const [recommendation, setRecommendation] = useState("");
  // const [txtVal, setTxtVal]=useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validation(recommendation)){
      props.next("recommendation", recommendation);
      gtag('event', "stepSix", {
        event_category: "signupFlow",
        event_label:recommendation,
      });
    }
  };

  const firstNameChange = (e) => {
    // ReactPixel.track("AddToCart",'Purchase', {currency: "USD", value: 30.00}));

    validation(e.target.id);
    setRecommendation(e.target.id);
  };

  const validation = (val) => {
    return true;
  };

  //   const preference = () => {
  //     if (props.data.pickPreference === "146259"){
  //       setTxtVal ="Vegan";
  //     }
  //     else if ( props.data.pickPreference === "146260"){
  //       setTxtVal ="Vegetarian";
  //     }
  //     else if ( props.data.pickPreference === "146262"){
  //       setTxtVal ="Gluten-free";
  //     }
  //     else if ( props.data.pickPreference === "146261"){
  //       setTxtVal ="Dairy-free";
  //     }
  //     else if ( props.data.pickPreference === "146263"){
  //       setTxtVal ="None";
  //     }

  //   }
  const submitButtonRef = useRef(null);
  useEffect(() => {
    if (props.paramValue === "super-box") {
      submitButtonRef.current.click();
      props.dataPush("recommendation", "recommendation2");

    }else if(props.paramValue === "super-box1"){
      submitButtonRef.current.click();
      props.dataPush("recommendation", "recommendation1");
    }
  }, [props.paramValue]);
  

  console.log("safet gjici", props.paramValue)
  var toSplit = props.data.pickPreferenceTitle;

  console.log(toSplit);
  // console.log("Six",props.data.pickPreference)
  return (
    <ScrollToTop>
      <div className="container">
        <h3 className="step-header-six">
          {props.data.pickedBox !== "148918" ? (
            <h3 className="step-header-six">
              Awesome! We think that the{" "}
              <p className="step-name">
                {props.data.pickPreferenceTitle.join(", ")}
              </p>{" "}
              #SuperSnacker box is PERFECT for you!
            </h3>
            ) : (
            <h3 className="step-header-six">
              Awesome! We think that the{" "}
              <p className="step-name">
                {props.data.pickPreferenceTitle.join(", ")}
              </p>{" "}
              Regular Box is PERFECT for you! 
            </h3>
          )}
        </h3>
        <div className="stepBox-display">
          <div
            className={`super-box ssupper ${
              props.data.pickedBox !== "148918" ? "rec-box" : ""
            }`}
          >
            {props.data.pickedBox !== "148918" ? (
              <div className="hover-recommand">
                <p>Our Recommendation</p>
              </div>
            ) : null}
            <img src="../images/100.png" alt="#"></img>
            <div>
              <p>
                <span>Get 2x the goodies! </span>
                <p>Each monthly box contains 20+ premium, hand-curated items</p>
                {/* <span>Average box value: $100+</span> */}
                <h6>
                  starts from <b>$56/mo</b>
                </h6>
              </p>
            </div>
            <form className="mtb-20" onSubmit={handleSubmit}>
              <input
                type="submit"
                name="button"
                id="recommendation2"
                value="Continue"
                onClick={firstNameChange}
              ></input>
            </form>
          </div>

          <div
            className={`super-box1 ssupper blueColor${
              props.data.pickedBox === "148918" ? "" : ""
            }`}
          >
            {props.data.pickedBox === "148918" ? (
              <div className="hover-recommand">
                <p>Our Recommendation</p>
              </div>
            ) : null}
            <img src="../images/60.png" alt="#"></img>
            <div>
              <p>
                <span>Regular Size</span>
                <p>Each monthly box contains 10+ premium, hand-curated items</p>
                {/* <span>Average box value: $60+</span> */}
                <h6>
                  starts from <b>$37/mo</b>
                </h6>
              </p>
            </div>
            <form className="mtb-20" onSubmit={handleSubmit}>
              <input
                type="submit"
                name="button"
                id="recommendation1"
                value="Continue"
                onClick={firstNameChange}
                ref={submitButtonRef}
              ></input>
            </form>
          </div>
        </div>
        <div className="step6-btns">
        <div className="go-back" onClick={props.prev}>
          <p>Go Back</p>
       
        </div>
        <Link to="/join-now-gifting" >
        <div className="give-gift-btn" >
          <p>Give a Gift instead</p>
        </div>
        </Link>

        </div>
      </div>
    </ScrollToTop>
  );
};

export default StepSix;
