import React from "react";


const Stressful = () =>{
    return(
          <div className="stress">
            <h1 className="main-title">Life is stressful enough.<br/> Eating healthy shouldn't have to be.</h1>
              <div className="shapeColor">
                    <div className="shapeImage">
                <img src="/images/shaoe.png"/>
               </div>
         
            
             
             
               <div className="shapeContent">
                 <div className="shapeInside">
                 <h2 className="main-title">CLEAN.FIT box is here to help!</h2>
                <div>
                    <img src="/images/ttblue.svg"/>
                    <p>Get 10+ or 20+  items each month delivered to your doorstep!</p>
                </div>
                <div>
                    <img src="/images/ttblue.svg"/>
                    <p>Try new healthy snacks + superfoods, plus bonus wellnes items.</p>
                </div>
                <div>
                    <img src="/images/ttblue.svg"/>
                    <p>Find healthy living tips & a home workout in every box.</p>
                </div>
                <div>
                    <img src="/images/ttblue.svg"/>
                    <p>Free U.S shipping!</p>
                </div>
                 </div>
               </div>
        
            </div>
          </div>
    )
}

export default Stressful