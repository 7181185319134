import React from "react";
import Lottie from "react-lottie-player";
import loaderLeaf from "../src/assets/loading_cleanfit.json";
function Loader() {
  return (
    <div className="main-loader">
      <Lottie
        loop
        animationData={loaderLeaf}
        play
        style={{ width: 350, height: 350 }}
      />
    </div>
  );
}

export default Loader;
