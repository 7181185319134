import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
// Import Swiper styles

// import required modules
import { Navigation, A11y, Autoplay } from "swiper";
import { generateImage } from "../../tools/constants";

const Features1 = ({ page }) => {
  // console.log(page);

  return (
    <>
      <div className="gray-background features">
        <h1 className="sub-title pb-20">{page.featuredText}</h1>

        <Swiper
          modules={[Navigation, A11y, Autoplay]}
          
          className=" mySwiper container"
          spaceBetween={0}
          watchOverflow="hidden"
          scrollbar={{ draggable: false }}
          // onSwiper={(swiper) => console.log(swiper)}
          // slideToClickedSlide={true}
          // preventClicksPropagation={false}
          // preventClicks={false}
          // allowTouchMove={false}
          clickable={true}
          centeredSlides={true}
          loop={true}
          speed={3000}
          autoplay={{
            delay: 0,
          }}
          breakpoints={{
            320: {
              slidesPerView: 2.2,
              spaceBetween: 0,
            },
            600: {
              slidesPerView: 3,
              spaceBetween: -10,
            },
            800: {
              slidesPerView: 4,
              spaceBetween: -10,
            },

            1200: {
              slidesPerView: 5,
            },
          }}
        >
          <div className="d-flex w-20 swiper-wrapper ">
            {page.featuresLogo.map(function (item, i) {
              return (
                <SwiperSlide className="d-flex w-20" key={i}>
                  <Link to="/">
                    <img
                      src={generateImage(item.feautersLogo.data.attributes.url)}
                      alt="#"
                    />
                  </Link>
                </SwiperSlide>
              );
            })}
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default Features1;
