import Nav from "./Components/Layout/Nav";

import { Link, useLocation } from "react-router-dom";
import RouteConfig from "./router";
import Mobile from "./Components/Layout/Navi";
import Foooter from "./Components/Layout/Footer";
import Loader from "./Loader";
import axios from "axios";
import { useEffect, useState } from "react";
function App() {
  console.warn = console.error = () => {};
  const location = useLocation();
  const [page, setPage] = useState({
    pages: [],
    isLoaded: false,
  });

  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/homepage?populate=deep,10")
      .then((res) => {
        setPage({
          pages: res.data.data.attributes,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
     
  }, []);

  
  if (page.isLoaded === false) return <Loader />;
  else {
  return (
    <>
         
      {location.pathname !== "/join-now" && location.pathname !== "/join-now-gifting" && location.pathname !== "/homepage/Bonus-Box-flow" &&  location.pathname !== "/homepage/join-now-gifting" && (
        <>
          {" "}
    
          <Nav />
          <Mobile />
        </>
      )}
       {   window.location.pathname.includes("homepage") && (
       <Link className="offer" to={
        window.location.pathname.includes("homepage")
          ? "/homepage/Bonus-Box-flow"
          : "/join-now"
      }>
                Get a FREE bonus box valued at $150 when you join! 🤯
          </Link> 
           )}
        { location.pathname !== "/join-now-gifting" && location.pathname !== "/homepage/Bonus-Box-flow" && location.pathname !== "/homepage/join-now-gifting"  && location.pathname !== "/homepage" &&   !window.location.pathname.includes("homepage") && (
       <Link className="offer" to={
        window.location.pathname.includes("homepage")
          ? "/homepage/Bonus-Box-flow"
          : "/join-now"
      }>
        {page.pages.offer}
          </Link> 
           )}
      <RouteConfig />
      {location.pathname !== "/join-now" &&
        location.pathname !== "/join-now-gifting" && <Foooter />}
    </>
  )};
}

export default App;
//Home page.pages
//PastBoxes pastBox.pastBoxes
//About about.about
//Blog"SUMMER" blog.blogs
//Single Blog singleBlog.singleBlog
//Faq question.questions
//Business business.business
//Causes causes.causes
//Coaching coaching.coaching
//BlogBox blogBox.blogBox
