import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FiMail } from "react-icons/fi";
import { generateImage } from "../../tools/constants";
import CustomForm from "../../tools/CustomForm";
const Subscribe = ({ page }) => {
  const ValidateSchema = Yup.object().shape({
    email: Yup.string().email("Enter a valid email!").required(""),
  });

  return (
    <div
      className="subscribe"
      style={{
        backgroundImage: `url(
        ${generateImage(page.formBackgroundImage.data.attributes.url)}
      )`,
      }}
    >
      <div className="mini-subscribe">
        <h3>{page.formHeading}</h3>
        {/* <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={ValidateSchema}
          onSubmit={(values) => {
            // same shape as initial values
            console.log(values);
          }}
        >
          {({ errors, touched }) => {
            // console.log(!touched.email);
            return (
              <Form>
                <div className="d-flex">
                  <div className="input">
                    <FiMail className="mail-icon" />
                    <Field placeholder="Hello" name="email" type="email" />
                  </div>

                  <button type="submit">{page.Submit.buttonText} </button>
                </div>
                {errors.email && touched.email ? (
                  <p className="error-msg error-subscribe">{errors.email}</p>
                ) : null}
              </Form>
            );
          }}
        </Formik> */}
        <CustomForm />
        <p>{page.formDescription} <b>$10 off</b> your first order!</p>
      </div>
    </div>
  );
};

export default Subscribe;
