import React from "react";
import { Link } from "react-router-dom";
import {TbError404} from "react-icons/tb"

const Errormsg = () => {

return(

<div className="container error-404">
 <div className="error-height">
 <div><img src="../images/404.svg" className="w-50"/></div>
   <div>
   <h1>This page does not exist.</h1>
   <Link to="/">Go Back</Link>
   </div>
 </div>
</div>


)



}

export default Errormsg;