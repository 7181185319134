import React from "react";
import { Link } from "react-router-dom";

const Foooter = () => {
  return (
    <div className="ptb-50 footer-color" id="footer">
      <div className="container footer-d">
        <div className="w-40  ">
          <Link  to={
                    window.location.pathname.includes("homepage")
                      ? "/homepage"
                      : "/"
                  }>
            <div className="nav-logo">
              {" "}
              <img src="../images/logo.svg" alt="#"></img>
            </div>
          </Link>

          <p>
          We make eating clean easy by delivering high-quality, delicious, convenient snacks and superfoods monthly.
          </p>
          <div className=" icon-display">
            <a href="https://www.facebook.com/cleanfitbox" target="_blank">
              <img src="../images/facebook1.svg" alt="#"></img>
            </a>
            <a href="https://www.instagram.com/clean.fit_box/" target="_blank">
              <img src="../images/instagram1.svg" alt="#"></img>
            </a>
            {/* <a href="#" target="_blank">
              <img src="../images/twitter1.svg" alt="#"></img>
            </a> */}
          </div>
        </div>
        <div className="width-15 footer-links">
          <h3>Learn More</h3>

          <Link to={
                    window.location.pathname.includes("homepage")
                      ? "/homepage/about"
                      : "/about"
                  }>About</Link>
          <Link to="/blog">Blog</Link>
          <Link to={
                    window.location.pathname.includes("homepage")
                      ? "/homepage/contact-us"
                      : "/contact-us"
                  }>Contact</Link>

          <Link to={
                    window.location.pathname.includes("homepage")
                      ? "/homepage/faq"
                      : "/faq"
                  }>FAQ's</Link>
          <Link to={
                    window.location.pathname.includes("homepage")
                      ? "/homepage/causes"
                      : "/causes"
                  }>Our Causes</Link>
          <Link to={
                    window.location.pathname.includes("homepage")
                      ? "/homepage/past-boxes"
                      : "/past-boxes"
                  }>Peek Inside</Link>
          <Link to={
                    window.location.pathname.includes("homepage")
                      ? "/homepage/business"
                      : "/business"
                  }>For Businesses</Link>
          <Link to={
                    window.location.pathname.includes("homepage")
                      ? "/homepage/the-never-list"
                      : "/the-never-list"
                  } >Never List</Link>
        </div>
        <div className="width-15 footer-links">
          <h3>Our Products</h3>

          <Link to="/join-now">#SuperSnacker Box</Link>
          <Link to="/join-now">Regular Box</Link>
          <Link to="/join-now-gifting">Give a Gift</Link>
          <Link to="/business">Bulk Orders</Link>
          <Link to="/coaching">Wellness Coaching</Link>
          
       
         
         
          {/* <Link to="/causes">Causes</Link> */}

          {/* <Link to="/coaching">Wellness Coaching</Link> */}
          {/* <Link to="/business">CLEAN.FIT for Business</Link> */}
        </div>
        <div className="width-15 footer-links">
          <h3>Contact Us</h3>

          <a href="mailto:info@cleanfitbox.com">info@cleanfitbox.com</a>

          <a href="https://www.subbly.co/login">My Account</a>
          <Link to="/contact-us">Contact Us</Link>
          <a href="https://clean-fit-box.cratejoy.com/customer/login?next_url=%2Fcustomer%2Faccount%3F" target="_blank">Cratejoy Login</a>
      
        </div>
        <div className="width-15 icon">
          <h3>Social</h3>
          <a href="https://www.facebook.com/cleanfitbox" target="_blank">
            <img src="../images/facebook1.svg" alt="#"></img>
          </a>
          <a href="https://www.instagram.com/clean.fit_box/" target="_blank">
            <img src="../images/instagram1.svg" alt="#"></img>
          </a>
          {/* <a href="#" target="_blank">
            <img src="../images/twitter1.svg" alt="#"></img>
          </a> */}
        </div>
      </div>
      <div className=" container">
        <div className="privacy">
          <p>© 2022 CLEANFIT | All Rights Reserved</p>
          <Link to="/policy">
            Privacy Policy | Terms of Service | Cookies Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Foooter;
