import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArticleBox from "../Components/Layout/ArticleBox";
import Loader from "../Loader";
import { generateImage } from "../tools/constants";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Helmet } from 'react-helmet';
const About = () => {
  const [about, setAbout] = useState({
    about: [],
    isLoaded: false,
  });
  const [blog, setBlog] = useState({
    blogs: [],
    isLoaded: false,
  });

  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/about?populate=deep,10")

      .then((resPages) => {
        axios
          .get("https://cms.cleanfitbox.com/api/blogs?populate=deep,10")

          .then((resBoxes) => {
            setAbout({
              about: resPages.data.data.attributes,
              isLoaded: true,
            });
            setBlog({
              blogs: resBoxes.data.data,
              isLoaded: true,
            });
          });
      })
      .catch((err) => console.log(err));
      // document.title="Subscription box for healthy snacks that are ACTUALLY healthy.";
      // document.querySelector('meta[name="description"]').setAttribute('content', "Learn more about our founder’s story. CLEAN.FIT: delicious and clean snacks, superfoods, and supplements delivered monthly.");
     
  }, []);

  if (about.isLoaded && blog.isLoaded) {
    // console.log(blog.blogs[0].attributes.updatedAt.substring(0, 10));

    var date = new Date(blog.blogs[0].attributes.updatedAt.substring(0, 10));
    var date2 = new Date(blog.blogs[1].attributes.updatedAt.substring(0, 10));
    var date3 = new Date(blog.blogs[2].attributes.updatedAt.substring(0, 10));
    var todayDate = new Date();
    var arr = [date, todayDate, date2, date3];
    // console.log(date);
    // console.log("today", todayDate);
    // console.log(arr.sort().reverse());

    // console.log(
    //   blog.blogs
    //     .filter((e) => e.attributes.Category === "Workout")
    //     .map((e) => e.attributes.workoutOne)
    // );
  }

  if (about.isLoaded === false || blog.isLoaded === false) return <Loader />;
  else {
    return (
      <>
         <Helmet>
           <title>Subscription box for healthy snacks that are ACTUALLY healthy.</title>
           <meta name="description" content="Learn more about our founder’s story. CLEAN.FIT: delicious and clean snacks, superfoods, and supplements delivered monthly." />
         </Helmet>
        <div className="about-banner">
          <img
            src={generateImage(about.about.aboutBanner.data.attributes.url)}
            className="w-100 about-none-desktop"
            alt="#"
          />
          <img
            src={generateImage(
              about.about.mobileAboutBanner.data.attributes.url
            )}
            className="w-100 about-none"
            alt="#"
          />
        </div>
        <div className="container">
          <AnimationOnScroll
            animateIn="animate__slower animate__fadeInUp  "
            // animateOut=""
            animateOnce
          >
            <h1 className="about-heading  ">{about.about.aboutHeader}</h1>
          </AnimationOnScroll>

          <div className="about-description">
            <AnimationOnScroll
              className="d-flex w-100 about-justify"
              animateIn="animate__slower animate__fadeInUp  "
              // animateOut=""
              animateOnce
            >
              <div className=" about-width">
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: about.about.cleanFamDescription,
                    }}
                  ></div>
                </p>
              </div>
              <div className="about-img about-width">
                <img
                  src={generateImage(
                    about.about.productImg.data.attributes.url
                  )}
                  alt="#"
                ></img>
              </div>
            </AnimationOnScroll>

            <AnimationOnScroll
              className="d-flex w-100 about-justify about-reverse"
              animateIn="animate__slower animate__fadeInUp  "
              // animateOut=""
              animateOnce
            >
              <div className="about-width about-img">
                <img
                  src={generateImage(about.about.groupImg.data.attributes.url)}
                  alt="#"
                />
              </div>
              <div className="about-width">
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: about.about.groupText,
                    }}
                  ></div>
                </p>
              </div>
            </AnimationOnScroll>
          </div>

          <div className="w-100 ">
            <AnimationOnScroll
              className="w-100"
              animateIn="animate__slower animate__fadeInUp  "
              // animateOut=""
              animateOnce
            >
              <img
                src={generateImage(
                  about.about.missionBanner.data.attributes.url
                )}
                alt="#"
                className="w-100 about-none-desktop"
              />
              <img
                src={generateImage(
                  about.about.missionMobileBanner.data.attributes.url
                )}
                alt="#"
                className="w-100 about-none"
              />
            </AnimationOnScroll>
            <div>
              <div className="mission-display pt-100">
                {" "}
                <AnimationOnScroll
                  className="mission-heading"
                  animateIn="animate__slower animate__fadeInLeft "
                  // animateOut=""
                  animateOnce
                >
                  <h1>{about.about.missionHeading}</h1>
                </AnimationOnScroll>
                <AnimationOnScroll
                  animateIn="animate__slower animate__fadeIn "
                  // animateOut=""
                  animateOnce
                >
                  <p>{about.about.missionDescription}</p>
                  <div className="mission-box">
                    {about.about.box.map((item) => (
                      <div className="mission-border">
                        <p>{item.text}</p>
                      </div>
                    ))}
                  </div>
                  <p> {about.about.boxDescription}</p>
                </AnimationOnScroll>
              </div>
            </div>
          </div>

          <div className="causes-bck-img  about-causes-none">
            <div>
              <h1>Our Causes</h1>
              <p>
                Trees give us life... 🌲 Quite literally! Did you know that
                trees:⁠ ​ Clean our air and water⁠ Create habitats for
                biodiversity⁠ Contribute to our health and wellbeing⁠ Can create
                jobs for social impact⁠ ​ We care deeply about the planet and
                creating a business that gives back to nature. 🌎 ⁠
              </p>
              <Link to="/causes">Learn More</Link>
            </div>
          </div>

          <div className="d-flex about-causes-none-mobile">
            <div className="w-100">
              <img
                src="../images/causes-img.png"
                alt="#"
                className="w-100"
              ></img>
            </div>
            <div>
              <h1>Our Causes</h1>
              <p>
                Trees give us life... 🌲 Quite literally! Did you know that
                trees:⁠ ​ Clean our air and water⁠ Create habitats for
                biodiversity⁠ Contribute to our health and wellbeing⁠ Can create
                jobs for social impact⁠ ​ We care deeply about the planet and
                creating a business that gives back to nature. 🌎 ⁠
              </p>
              <Link to="/causes">Learn More</Link>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default About;
