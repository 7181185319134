import axios from "axios";
import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
// import Loader from "../../Loader";
import AllArticles from "../articles/AllArticles";
import Healthy from "../articles/Healthy";
import Lifestyle from "../articles/Lifestyle";
import Recipe from "../articles/Recipe";
import Workout from "../articles/Workout";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Loader from "../../Loader";
const ArticlesTb = () => {
  let { name } = useParams();

  const [article, setArticle] = useState(name);
console.log("article",article)
  useEffect(() => {
    setArticle(name);
  }, [name]);

  const articleOption = {
    all: <AllArticles />,
    workouts: <Workout />,
    recipes: <Recipe />,
    healthy: <Healthy />,
    lifestyle: <Lifestyle />,
  };

  const [singleBlog, setSingleBlog] = useState({
    singleBlog: [],
    isLoaded: false,
  });

  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/single-blog?populate=deep,10")
      .then((resBoxes) => {
        setSingleBlog({
          singleBlog: resBoxes.data.data.attributes,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  if (singleBlog.isLoaded === false) return <Loader />;
  else {
    return (
      <div className="container">
        <div
          className="articles"
          //  animateIn="animate__slower animate__fadeInUp"
          //  animateOnce
        >
          <h1 className="article-title">Blogs</h1>
          <ul className="pb-50">
            {singleBlog.singleBlog.tabs.map((item, i) => (
              <Link
                to={`/articles/${item.heading.toLowerCase()}`}
                className={`articles-tab-link ${
                  article === item.heading.toLowerCase() ? "article-act" : ""
                }`}
              >
                <li>
                  <p>{item.heading}</p>
                </li>
              </Link>
            ))}
          </ul>
          <div className="all-articles-height">{articleOption[article]}</div>
        </div>
      </div>
    );
  }
};

export default ArticlesTb;
