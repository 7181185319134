import { Link } from "react-router-dom";
import React, { useState } from "react";
const Nav = () => {
  const [active, setActive] = useState(1);
  return (
    <div className="nav">
      <div className="container">
        <Link
           to={
            window.location.pathname.includes("homepage")
              ? "/homepage"
              : "/"
          }
          onClick={() => setActive(1)}
          className={`${active === 1 ? "nav-active" : ""}`}
        >
          <div className="nav-logo">
            {" "}
            <img src="../images/logo.svg"></img>
          </div>
        </Link>
        <div className="nav-list">
          <ul>
            <li>
              <Link
                onClick={() => setActive(0)}
                className={`${active === 0 ? "nav-active" : ""}`}
               to={
            window.location.pathname.includes("homepage")
              ? "/homepage/past-boxes"
              : "/past-boxes"
          }
              >
                Peek Inside
              </Link>
            </li>
            <li>
              <a
                onClick={() => setActive(2)}
                className={`${active === 2 ? "nav-active" : ""}`}
                href="https://checkout.cleanfitbox.com/account/auth/login"
              >
                Login
              </a>
            </li>
            <li className="sub-nav">
              <Link
                onClick={() => setActive(3)}
                className={`${active === 3 ? "nav-active sub-nav" : ""} `}
                to={
                  window.location.pathname.includes("homepage")
                    ? "/homepage/Bonus-Box-flow"
                    : "/join-now"
                }
              >
                Subscribe
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Nav;
