import axios from "axios";
// import Sllugu from "../router/custom";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Relevant from "../Components/Layout/Relevant";
import Subscribe from "../Components/Layout/Subscribe";
import Loader from "../Loader";
import { generateImage } from "../tools/constants";
import { AnimationOnScroll } from "react-animation-on-scroll";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
const Summer = () => {
  const params = useParams();
  console.log(params);

  const [blogBox, setBlogBox] = useState({
    blogBox: [],
    isLoaded: false,
  });
  console.log(blogBox);
  const [blog, setBlog] = useState({
    blogs: [],
    isLoaded: false,
  });
  const [singleblog, setSingleblog] = useState({
    blogBox: [],
    isLoaded: false,
  });

  const [page, setPage] = useState({
    pages: [],
    isLoaded: false,
  });

  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/homepage?populate=deep,10")
      .then((res) => {
        setPage({
          pages: res.data.data.attributes,
          isLoaded: true,
        });
      });

    axios
      .get(
        `https://cms.cleanfitbox.com/api/blogs?filters[$and][0][slug][$eq]=${params.slug}&populate=deep,10`
      )
      .then((res) => {
        setSingleblog({
          pages: res.data.data.attributes,
          isLoaded: true,
        });
      });

    axios
      .get(
        `https://cms.cleanfitbox.com/api/blogs?filters[$and][0][slug][$eq]=${params.slug}&populate=deep,10`
      )

      .then((res) => {
        setBlogBox({
          blogBox: res.data,
          isLoaded: true,
        });
        console.log(params);
        axios
          .get(
            "https://cms.cleanfitbox.com/api/blogs?populate=deep,10&pagination[start]=0&pagination[limit]=-1"
          )

          .then((resBoxes) => {
            setBlog({
              blogs: resBoxes.data.data,
              isLoaded: true,
            });
          });
      })
      .catch((err) => console.log);
  }, [params.slug]);
  // console.log("13", blog.blogs);

  if (
    blogBox.isLoaded === false ||
    page.isLoaded === false ||
    blog.isLoaded === false
  )
    return <Loader />;
  else {
    return (
      <>
       <Helmet>
           <title>Subscription box for healthy snacks that are ACTUALLY healthy.</title>
           <meta name="description" content="Learn more about our founder’s story. CLEAN.FIT: delicious and clean snacks, superfoods, and supplements delivered monthly." />
         </Helmet>
        <div className="container">
          <div></div>
          <div className="summer-banner ">
            <div className="summer-img">
              <img
                src={generateImage(
                  blogBox.blogBox.data[0].attributes.Banner.data.attributes.url
                )}
                alt="#"
                className="w-100"
              />
            </div>
            <div className="summer-box summer-none">
              <AnimationOnScroll
                animateIn="animate__slower animate__fadeInUp "
                // animateOut=""
                animateOnce
              >
                <h1 className="summer-title">
                  {blogBox.blogBox.data[0].attributes.mostViewedHeading}
                  Recent Posts
                </h1>
              </AnimationOnScroll>

              <div>
                {" "}
                <div className="summer-wrap mb-50 ">
                  {blog.blogs
                    .slice(Math.max(blog.blogs.length - 3, 0))
                    .sort()
                    .reverse()
                    .map((e, i) => {
                 
                      const date = e.attributes.createdAt;
                      var now = moment
                        .utc(date, "YYYY-MM-DDTHH:mm:ssZ")
                        .format("YYYY-MM-DD");
                      return (
                        <Relevant
                          // className="display-group"
                          key={e.id}
                          id={e.id}
                          slug={e.attributes.slug}
                          photoUrl={
                            e.attributes.blogBox.image.data.attributes.url
                          }
                          title={e.attributes.blogBox.title}
                          hashtag={e.attributes.Category}
                          data={now}
                        />
                      );
                    })}
                </div>
                <Link className="all-display" to="/articles/all">
                  See All
                </Link>
              </div>
            </div>
          </div>
          <div className="  summer">
            <AnimationOnScroll
              className="summer-display"
              animateIn="animate__slower animate__fadeInUp "
              // animateOut=""
              animateOnce
            >
              <div className="summer-description">
                {blogBox.blogBox.data[0].attributes.blogBox.title === "" ? "" :     <h1 className="summer-header">
                  {blogBox.blogBox.data[0].attributes.blogBox.title}
                </h1>}
            

                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {blogBox.blogBox.data[0].attributes.descriptionBox.trim()}
                </ReactMarkdown>
              </div>

              <div></div>
            </AnimationOnScroll>
            <div className="summer-box summer-block">
              <AnimationOnScroll
                animateIn="animate__slower animate__fadeInUp "
                // animateOut=""
                animateOnce
              >
                <h1 className="summer-title">
                  {blogBox.blogBox.data[0].attributes.mostViewedHeading}
                  Recent Posts
                </h1>
              </AnimationOnScroll>

              <div>
                {" "}
                <div className="summer-wrap mb-50 ">
                {blog.blogs
                    .slice(Math.max(blog.blogs.length - 3, 0))
                    .sort()
                    .reverse()
                    .map((e, i) => {
                 
                      const date = e.attributes.createdAt;
                      var now = moment
                        .utc(date, "YYYY-MM-DDTHH:mm:ssZ")
                        .format("YYYY-MM-DD");
                      return (
                        <Relevant
                          // className="display-group"
                          key={e.id}
                          id={e.id}
                          slug={e.attributes.slug}
                          photoUrl={
                            e.attributes.blogBox.image.data.attributes.url
                          }
                          title={e.attributes.blogBox.title}
                          hashtag={e.attributes.Category}
                          data={now}
                        />
                      );
                    })}
                </div>
              </div>
              <Link className="all-display" to="/articles/all">
                See All
              </Link>
            </div>
          </div>
        </div>
        <Subscribe page={page.pages} />
      </>
    );
  }
};

export default Summer;
