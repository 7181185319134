import { useEffect, useState } from "react";
import { Sling as Hamburger } from "hamburger-react";
import { Link } from "react-router-dom";
import "animate.css";
import disableScroll from "disable-scroll";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

function Mobile() {
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {

  }, [disableScroll]);
  isOpen ? disableScroll.on() : disableScroll.off();
  return (
    <>
      <div className="mobile-header container">
        <div className="justify-space ptb-10 ">
          <Link
            to="/"
            className="mobile-logo"
            onClick={() => {
              setOpen(false);
            }}
          >
            <div>
              {" "}
              <img src="../images/logo.svg"></img>
            </div>
          </Link>

          <div className="hamburger-menu">
            <Hamburger
              toggled={isOpen}
              toggle={setOpen}
              size={25}
              direction="right"
            />
          </div>
        </div>

        <div
          className={
            isOpen ? "mobile-menu-container opened" : "mobile-menu-container"
          }
        >
          <ul>
            {isOpen ? (
              <>
                {" "}
                <Link
                  onClick={() => {
                    setOpen(false);
                  }}
                  to="/past-boxes"
                >
                  <li className="animate__animated animate__backInLeft">
                  Peek Inside
                    <MdOutlineKeyboardArrowRight className="mobile-icon" />
                  </li>
                </Link>
          
               
                <Link
                  onClick={() => {
                    setOpen(false);
                  }}
                  to="/business"
                >
                  <li className="animate__animated animate__backInLeft">
                    CLEAN.FIT for Business
                    <MdOutlineKeyboardArrowRight className="mobile-icon" />
                  </li>
                </Link>
                <a
                  href="https://checkout.cleanfitbox.com/account/auth/login"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <li className="animate__animated animate__backInLeft">
                    Login
                    <MdOutlineKeyboardArrowRight className="mobile-icon" />
                  </li>
                </a>
                <Link
                  className="mobile-sub"
                  to={
                    window.location.pathname.includes("homepage")
                      ? "/homepage/Bonus-Box-flow"
                      : "/join-now"
                  }
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <li className="animate__animated animate__backInLeft sub-mobile">
                    Subscribe
                  </li>
                </Link>
              </>
            ) : null}
          </ul>
        </div>
      </div>
    </>
  );
}
export default Mobile;
