import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import { generateImage } from "../tools/constants";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Helmet } from 'react-helmet';
const Causes = () => {
  const [causes, setCauses] = useState({
    causes: [],
    isLoaded: false,
  });

  useEffect(() => {
    axios
      .get("https://cms.cleanfitbox.com/api/causes?populate=deep,10")
      .then((res) => {
        setCauses({
          causes: res.data.data.attributes,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
      // document.title="Subscription box for healthy snacks that are ACTUALLY healthy.";
      // document.querySelector('meta[name="description"]').setAttribute('content', "One tree planted for every box sold. CLEAN.FIT: delicious and clean snacks, superfoods, and supplements delivered monthly. Organic and all-natural.");

  }, []);

  console.log(causes.pages);

  if (causes.isLoaded === false) return <Loader />;
  else {
    return (
      <>
      <Helmet>
           <title>Subscription box for healthy snacks that are ACTUALLY healthy.</title>
           <meta name="description" content="One tree planted for every box sold. CLEAN.FIT: delicious and clean snacks, superfoods, and supplements delivered monthly. Organic and all-natural." />
         </Helmet>
        {" "}
        <div className="container">
          <h1 className="causes-heading">{causes.causes.causesHeading}</h1>
          <div className="d-flex causes w-100">
            <div className="causes-img">
              <img
                src={generateImage(causes.causes.causesImg.data.attributes.url)}
                alt="#"
                className="w-100 causes-none-desktop"
              ></img>
              <img
                src={generateImage(
                  causes.causes.causesMobileImg.data.attributes.url
                )}
                className="w-100 causes-none"
                alt="#"
              ></img>
            </div>
            <AnimationOnScroll
              className="our-causes"
              animateIn="animate__slower animate__fadeInRight  "
              // animateOut=""
              animateOnce
            >
              <h2 className="causes-subheading">
                {" "}
                A tree planted for every box sold🌲
              </h2>
              <p>
                <div
                  dangerouslySetInnerHTML={{ __html: causes.causes.causesText }}
                ></div>
              </p>
            </AnimationOnScroll>
          </div>

          <div className="d-flex pt-100 w-100 causes-reverse">
            <AnimationOnScroll
              className="donate-causes"
              animateIn="animate__slower animate__fadeInLeft  "
              // animateOut=""
              animateOnce
            >
              <h2 className="causes-subheading">
                A healthy snack for everyone! 😋
              </h2>
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: causes.causes.donationText,
                  }}
                ></div>
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll
              className="group-causes"
              animateIn="animate__slower animate__fadeIn  "
              // animateOut=""
              animateOnce
            >
              <img
                src={generateImage(
                  causes.causes.donationImg.data.attributes.url
                )}
                className="w-100"
                alt="#"
              ></img>
            </AnimationOnScroll>
          </div>

          <div className="d-flex pbt-100 mb-150 causes">
            <AnimationOnScroll
              className="causes-selfie"
              animateIn="animate__slower animate__fadeIn "
              // animateOut=""
              animateOnce
            >
              <img
                src={generateImage(causes.causes.selfieImg.data.attributes.url)}
                alt="#"
              ></img>
            </AnimationOnScroll>
            <AnimationOnScroll
              className="donation"
              animateIn="animate__slower animate__fadeInRight  "
              // animateOut=""
              animateOnce
            >
              <h2 className="causes-subheading">Giving the gift of life ❤️ </h2>
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: causes.causes.organDonationText,
                  }}
                ></div>
              </p>
            </AnimationOnScroll>
          </div>
        </div>
        <div className="lightblue-bck">
          <AnimationOnScroll
            className="container causes-video"
            animateIn="animate__slower animate__fadeInUp"
            // animateOut=""
            animateOnce
          >
            <iframe
              width="100%"
              height="500"
              src="https://www.youtube.com/embed/JUsrdlCOx_0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </AnimationOnScroll>
          <div className="pb-50 donate-logo">
            <AnimationOnScroll
              animateIn="animate__slower animate__fadeInLeft  "
              // animateOut=""
              animateOnce
            >
              <img
                src={generateImage(
                  causes.causes.donateLogo.data.attributes.url
                )}
                alt="#"
              ></img>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__slower animate__fadeInRight  "
              // animateOut=""
              animateOnce
            >
              {/* <p>{causes.causes.logoText}</p> */}
              <p>
                We urge you to consider giving the gift of life, by{" "}
                <a
                  className="donate-link"
                  href="https://www.donatelife.net/register/"
                >
                  registering to be an organ donor here. ♥
                </a>
              </p>
            </AnimationOnScroll>
          </div>
        </div>
      </>
    );
  }
};

export default Causes;
